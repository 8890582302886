p,
.gl-body,
.gl-paragraph {
  font-size: map-fetch($fonts, 'body' 'default' 'sizes' 'default');
  line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 'default');
  margin-bottom: map-fetch($fonts, 'body' 'default' 'margins' 'default');
}

.gl-body,
.gl-paragraph {
  &--l {
    @include gl-body-style('l');
  }

  &--s {
    @include gl-body-style('s');
  }

  &--xs {
    @include gl-body-style('xs');
  }
}

// Remove margin. Use vspace class names instead
.gl-paragraph {
  margin-bottom: 0;
  margin-top: 0;
}
