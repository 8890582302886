$square-list-item-margin: 5px !default;
$square-list-item-font-size: map-fetch(
  $fonts,
  'body' 'default' 'sizes' 's'
) !default;
$square-list-item-font-family: $font-body-family !default;
$square-list-item-font-style: $font-body-style !default;
$square-list-item-font-weight: $font-body-weight !default;
$square-list-item-background: none !default;
$square-list-background-color: var(--gl-color-secondary) !default;
$square-list-border-color: 1px solid var(--gl-color-dark-grey) !default;
$square-list-border-color-hover: $square-list-border-color !default;

.gl-square-list {
  background-color: $square-list-background-color;
  display: flex;
  flex-wrap: wrap;
  margin: 0 (-$square-list-item-margin) (-$square-list-item-margin) 0;
  max-height: none;
  overflow-y: auto;
  padding: 0;
  width: 100%;

  &__element {
    align-items: center;
    background: $square-list-item-background;
    border: $square-list-border-color;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    font-family: $square-list-item-font-family;
    font-size: $square-list-item-font-size;
    font-style: $square-list-item-font-style;
    font-weight: $square-list-item-font-weight;
    height: 40px;
    justify-content: center;
    line-height: 1.1;
    margin: 0 $square-list-item-margin $square-list-item-margin 0;
    min-width: 40px;
    padding: 0 14px;
    position: relative;
    text-align: center;
    transition: border map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
    width: auto;

    &:active,
    &:hover,
    &:focus {
      border: $square-list-border-color-hover;
      outline: none;
    }
  }

  &__item {
    list-style: none;
  }

  &__item--active & {
    &__element {
      &,
      &:active,
      &:hover,
      &:focus {
        border-color: var(--gl-color-primary);
        font-family: $font-body-family--bold;
        font-style: $font-body-style--bold;
        font-weight: $font-body-weight--bold;
      }
    }
  }
}
