.gl-radio-group {
  text-align: left;

  .gl-form-hint {
    padding-left: 0;
  }

  &__label {
    display: block;
    margin-bottom: $space;
  }
}

.gl-radio-input {
  display: flex;
  font-size: $input-label-font-size;

  &__option {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 20px;
    position: relative;

    @media (pointer: fine) {
      &:hover .gl-radio-input__label::before {
        border-color: $checkbox-border-color-hover;
      }
    }

    &-content {
      margin-top: $space;
      padding-left: $checkbox-size + $checkbox-margin-right;
    }

    .gl-icon,
    img {
      margin-left: 40px;
    }

    .gl-flag {
      height: 24px;
    }

    &--expanded-icon {
      justify-content: space-between;

      .gl-icon,
      img {
        margin-left: auto;
      }
    }

    &--no-label {
      .gl-icon,
      img {
        margin-left: 0;
      }
    }
  }

  &__label {
    display: inline-flex;
    line-height: $checkbox-size; // align with checkbox
    min-height: $checkbox-size; // align with checkbox
    position: relative;

    // Circle
    &::before {
      background: $checkbox-background;
      border: $checkbox-border-width solid;
      border-color: $checkbox-border-color;
      border-radius: 50%;
      box-sizing: border-box;
      content: '';
      cursor: pointer;
      display: block;
      flex-shrink: 0;
      height: $radio-size;
      margin: ($checkbox-size - $radio-size) / 2; // align with checkbox
      margin-right: ($checkbox-size - $radio-size) / 2 + $checkbox-margin-right; // align with checkbox
      pointer-events: none;
      text-transform: none;
      transition: map-fetch($motion, 'duration' 'fast')
        map-fetch($motion, 'ease' 'default');
      width: $radio-size;
    }

    // Dot
    &::after {
      background: $radio-check-color;
      border-radius: 50%;
      box-sizing: border-box;
      content: '';
      display: block;
      height: $radio-check-size;
      left: ($checkbox-size - $radio-check-size) / 2; // align with checkbox
      position: absolute;
      top: ($checkbox-size - $radio-check-size) / 2; // align with checkbox
      transform: scale(0);
      transition: map-fetch($motion, 'duration' 'fast')
        map-fetch($motion, 'ease' 'default');
      width: $radio-check-size;
    }
  }

  &__input {
    height: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    width: 0;

    &:checked ~ .gl-radio-input__label {
      font-weight: $radio-label-font-weight-checked;

      // Circle
      &::before {
        background: $checkbox-background-checked;
        border-color: $checkbox-border-color-checked;
      }

      // Dot
      &::after {
        transform: scale(1);
      }
    }
  }

  /*
   * Direction variations
   */

  &--horizontal {
    flex-direction: row;
  }

  &--vertical {
    flex-direction: column;

    .gl-radio-input__option {
      margin-right: 0;
    }

    .gl-radio-input__option + .gl-radio-input__option,
    .gl-radio-input__option-content + .gl-radio-input__option {
      margin-top: $radio-group-vertical-spacing;
    }
  }
}
