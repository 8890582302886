/// Font Face Loader
///
/// Loads a specific font file based on the fontface selected
///
/// @access private
/// @group fonts
///
/// @param {string} $font-name - The font family name. First element is used if this value is a list.
/// @param {string} $file-path - The file path to the font family.
/// @param {string} $font-weight [normal] - The font weight of the font family.
/// @param {string} $font-style [normal] - The font style of the font family.
/// @param {map} $font-extra-type - Contains an extra font type definition///format* and///type*.
///
@mixin fontface-loader(
  $font-name,
  $file-path,
  $font-weight: normal,
  $font-style: normal,
  $font-display: auto,
  $font-extra-type: null,
  $font-extra-format: null
) {
  @font-face {
    font-display: $font-display;
    font-family: quote(nth($font-name, 1));
    font-style: $font-style;
    font-weight: $font-weight;

    @if ($font-extra-type) {
      $type: map-fetch($font-extra-type, 'type' 'fonttype');
      $format: map-fetch($font-extra-type, 'type' 'format');

      src: url($file-path + '.woff2') format('woff2'),
        url($file-path + '.' + $type) format($format);
    } @else {
      @if ($font-extra-format) {
        src: url($file-path + '.otf') format($font-extra-format),
          url($file-path + '.otf') format($font-extra-format);
      } @else {
        src: url($file-path + '.woff2') format('woff2'),
          url($file-path + '.woff') format('woff');
      }
    }
  }
}

/// Font Face
///
/// Defines which format file and style of a font should be loaded
///
/// @access private
/// @group fonts
///
/// @param {string} $font-name - The font family name.
///
@mixin fontface($font) {
  $fontName: map-fetch($fonts, 'availablefonts' $font 'fontname');
  $fontStyles: map-fetch($fonts, 'availablefonts' $font 'styles');
  $fontFile: null;
  $fontWeight: null;
  $fontStyle: null;
  $fontDisplay: map-fetch($fonts, 'availablefonts' $font 'fontdisplay');
  $fontExtraType: map-fetch($fonts, 'availablefonts' $font 'fontextratype');
  $fontExtraFormat: map-fetch($fonts, 'availablefonts' $font 'fontextraformat');

  @each $style in $fontStyles {
    $styleRefKey: nth($style, 1);
    $styleRef: map-fetch($fonts, 'availablefonts' $font 'styles' $styleRefKey);
    @each $styleRef in $style {
      $fontFile: map-fetch(
        $fonts,
        'availablefonts' $font 'styles' $styleRefKey 'file'
      );
      $fontWeight: map-fetch(
        $fonts,
        'availablefonts' $font 'styles' $styleRefKey 'weight'
      );
      $fontStyle: map-fetch(
        $fonts,
        'availablefonts' $font 'styles' $styleRefKey 'style'
      );
    }
    @include fontface-loader(
      $fontName,
      $fontsPath + $fontFile,
      $fontWeight,
      $fontStyle,
      $fontDisplay,
      $fontExtraType,
      $fontExtraFormat
    );
  }
}
