h1,
h2,
h3,
h4,
h5,
h6,
.gl-heading {
  @include gl-heading-style(null, 'primary');

  margin-bottom: $heading-margin-bottom;
  text-transform: uppercase;
}

.gl-heading--xxl,
h1 {
  @include gl-heading-style('xxl');
}

.gl-heading--xl,
h2 {
  @include gl-heading-style('xl');
}

.gl-heading--l,
h3 {
  @include gl-heading-style('l');
}

.gl-heading--m,
h4 {
  @include gl-heading-style('m');
}

.gl-heading--s,
h5 {
  @include gl-heading-style('s');
}

.gl-heading {
  &--regular {
    @include gl-heading-style(null, 'regular');
  }

  &--no-margin {
    margin: {
      bottom: 0;
      top: 0;
    }
  }
}
