$menu-item-active-text-color: var(--gl-color-primary) !default;
$menu-item-active-font-weight: bold !default;
$menu-item-transition: color map-fetch($motion, 'duration' 'fast')
  map-fetch($motion, 'ease' 'default') !default;

.gl-menu {
  margin: 0;
  padding: 0;

  &__item {
    list-style: none;
    position: relative;

    &--active {
      font-weight: $menu-item-active-font-weight;
      position: relative;

      .gl-menu__element {
        color: $menu-item-active-text-color;
      }
    }
  }

  &__element {
    background: none;
    border: none;
    cursor: pointer;
    font: inherit;
    margin: 0;
    padding: 0;
    text-align: inherit;
    transition: $menu-item-transition;
    width: 100%;

    &:focus {
      outline: none;
    }

    &:focus,
    &:hover {
      color: $menu-item-active-text-color;
    }
  }
}
