.gl-time {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;

  &--bordered {
    border: 1px solid var(--gl-color-primary);
    padding: 10px 20px;
  }
}
