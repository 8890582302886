$divider-border-width: 1px !default;
$divider-border-style: solid !default;
$divider-border-color: var(--gl-color-dark-grey) !default;
$divider-dark-border-color: var(--gl-color-dark-grey) !default;
$divider-light-border-color: var(--gl-color-secondary) !default;

.gl-divider {
  border: 0;
  border-bottom: $divider-border-width $divider-border-style
    $divider-border-color;

  &--dark {
    border-bottom-color: $divider-dark-border-color;
  }

  &--light {
    border-bottom-color: $divider-light-border-color;
  }
}
