$tab-height: 60px !default;
$tab-background-color: var(--gl-color-secondary) !default;
$tab-border-width: 1px !default;
$tab-border-color: var(--gl-color-light-grey) !default;
$tab-item-font-size: map-fetch($fonts, 'labels' 'sizes' 'm') !default;
$tab-item-font-family: $font-label-family !default;
$tab-item-font-weight: $font-label-weight !default;
$tab-item-letter-spacing: map-fetch(
  $fonts,
  'labels' 'letter-spacing' 'default'
) !default;
$tab-item-text-transform: uppercase !default;
$tab-item-height: 58px !default;
$tab-item-margin: 10px !default;
$tab-item-padding: 20px !default;
$tab-item-background-color: var(--gl-color-secondary) !default;
$tab-item-border-color: var(--gl-color-light-grey) !default;
$tab-item-border-style: solid !default;
$tab-item-border-width: 3px !default;
$tab-item-text-color: var(--gl-color-dark-grey) !default;
$tab-item-padding-x: 10px !default;
$tab-item-padding-y: 16px !default;
$tab-item-border-color-hover: var(--gl-color-dark-grey) !default;
$tab-item-border-top: $tab-item-border-width solid transparent !default;
$tab-item-active-background-color: var(--gl-color-secondary) !default;
$tab-item-active-border-width: $tab-item-border-width !default;
$tab-item-active-border-color: var(--gl-color-primary) !default;
$tab-item-active-text-color: var(--gl-color-primary) !default;
$tab-item-transition: color map-fetch($motion, 'duration' 'fast')
  map-fetch($motion, 'ease' 'default') !default;
$tab-panel-background-color: var(--gl-color-secondary) !default;

.gl-tab {
  align-items: center;
  background-color: $tab-background-color;
  border-bottom: $tab-border-width $tab-item-border-style $tab-item-border-color;
  border-top: $tab-border-width $tab-item-border-style $tab-item-border-color;
  height: $tab-height;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 10;

  @include gl-respond-to('large') {
    align-items: left;
    justify-content: left;
  }

  &__tab-list {
    border-bottom: $tab-border-width $tab-item-border-style
      $tab-item-border-color;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    padding: 0;
    position: relative;
    scrollbar-width: none;
    user-select: none;
    white-space: nowrap;

    &::-webkit-scrollbar {
      background: transparent;
      display: none;
      height: 0;
      width: 0;
    }

    &::after {
      content: '';
      padding-right: $tab-item-padding / 2;
    }

    &--mobile {
      overflow-x: scroll;
    }

    &--center {
      justify-content: center;
    }

    &--dragging {
      cursor: grab;

      .gl-tab__item {
        pointer-events: none;
      }
    }
  }

  &__item {
    background-color: $tab-item-background-color;
    border-bottom: $tab-item-border-width solid transparent;
    border-top: $tab-item-border-top;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    font-family: $tab-item-font-family;
    font-size: $tab-item-font-size;
    font-weight: $tab-item-font-weight;
    height: $tab-item-height;
    letter-spacing: $tab-item-letter-spacing;
    margin: 0 $tab-item-margin;
    opacity: 1;
    overflow: hidden;
    padding: $tab-item-padding 0;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: $tab-item-text-transform;
    transition: $tab-item-transition;
    white-space: nowrap;

    &:first-child {
      margin-left: $tab-item-padding;
    }

    &:hover {
      border-bottom: $tab-item-border-width solid $tab-item-border-color-hover;
    }

    &--active {
      background-color: $tab-item-active-background-color;
      border-bottom: $tab-item-active-border-width $tab-item-border-style
        $tab-item-active-border-color;
      color: $tab-item-active-text-color;
      font-family: $font-label-family--bold;
      font-weight: $font-label-weight--bold;
      outline: 0;

      &:hover {
        border-bottom: $tab-item-active-border-width $tab-item-border-style
          $tab-item-active-border-color;
      }
    }

    &:not(&--active):hover {
      border-bottom: $tab-item-border-width solid $tab-item-border-color-hover;
    }
  }

  &__panel {
    background-color: $tab-panel-background-color;
  }
}
