$dropdown-icon-margin: 5px !default;
$dropdown-square-list-item-margin: 5px !default;
$dropdown-square-list-background-color: var(--gl-color-secondary) !default;

.gl-dropdown-custom,
.gl-dropdown-native {
  cursor: pointer;
  display: block;
  position: relative;

  &__select {
    align-items: center;
    appearance: none;
    background: $input-background-color;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    color: $input-text-color;
    cursor: pointer;
    display: flex;
    font-size: $input-font-size;
    height: $input-height;
    justify-content: space-between;
    line-height: $input-line-height;
    margin: 0;
    padding: 0 $input-padding-x;
    position: relative;
    transition: border map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
    width: 100%;

    &:focus {
      outline: none;
    }

    &:focus,
    &:hover {
      border-color: $input-border-color-hover;
    }
  }

  &__select-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--no-ellipse {
      text-overflow: inherit;
    }
  }

  &__select-icon {
    flex-shrink: 0;
    transition: transform map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
  }

  &--small & {
    &__select,
    &__option {
      padding-left: $input-padding-x-small;
      padding-right: $input-padding-x-small - 4px;
    }
  }
}

.gl-dropdown-native {
  &__select-element {
    appearance: none;
    border: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;

    // Safari Bug Fix for native dropdowns.
    // Should be removed as soon a new version is deployed -> https://bugs.webkit.org/show_bug.cgi?id=202198
    @include gl-safari-only() {
      text-rendering: auto !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.gl-dropdown-custom {
  &__options {
    background-color: $input-background-color;
    border: $input-border-width solid $input-border-color;
    box-sizing: border-box;
    left: 0;
    max-height: 195px;
    opacity: 0;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 100%;
    transform: translateY(-1px);
    transition: opacity map-fetch($motion, 'duration' 'fast')
        map-fetch($motion, 'ease' 'default'),
      visibility map-fetch($motion, 'duration' 'fast')
        map-fetch($motion, 'ease' 'default')
        map-fetch($motion, 'duration' 'fast');
    visibility: hidden;
    width: 100%;
    z-index: 2;

    &--squared {
      padding: 20px 10px;
    }

    &--with-before {
      .gl-square-list {
        padding-top: 16px;
      }
    }

    &--with-after {
      .gl-square-list {
        padding-bottom: 16px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__option {
    @include gl-body-style();

    background: none;
    border: none;
    border-bottom: $dropdown-option-border-width solid
      $dropdown-option-border-color;
    color: $input-text-color;
    cursor: pointer;
    display: block;
    font-size: $input-font-size;
    line-height: $input-line-height;
    margin: 0;
    padding: 10px $input-padding-x;
    text-align: inherit;
    transition: background map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
    width: 100%;

    &:focus {
      outline: none;
    }

    &:focus,
    &:hover,
    &--focused,
    &--selected {
      background-color: $dropdown-option-background-color-hover;
    }

    &--selected {
      font-weight: $font-body-weight--bold;
    }
  }

  &__item {
    list-style: none;

    &:focus {
      outline: none;
    }

    &:last-child &__option {
      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  &__square-list {
    background-color: $dropdown-square-list-background-color;
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$dropdown-square-list-item-margin)
      (-$dropdown-square-list-item-margin) 0;
    max-height: none;
    overflow-y: auto;
    padding: 0;
    width: 100%;
  }

  &--open & {
    &__select {
      border-color: $input-border-color-hover;
    }

    &__select-icon {
      transform: scaleY(-1);
    }

    &__options {
      border-color: $input-border-color-hover;
      border-top-color: $dropdown-option-border-color;
      opacity: 1;
      transition: opacity map-fetch($motion, 'duration' 'fast')
          map-fetch($motion, 'ease' 'default'),
        visibility map-fetch($motion, 'duration' 'fast')
          map-fetch($motion, 'ease' 'default') 0s;
      visibility: visible;
    }
  }

  // avoid scrollbar in dropdown menu
  &--no-max-height &__options {
    max-height: unset;
  }

  // a hidden <select/> element is kept for autofill feature of browsers
  &__select-element {
    appearance: none;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }
}
