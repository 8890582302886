$time-progress-border-color: var(--gl-color-primary) !default;
$time-progress-border-width: 1px !default;
$time-progress-wrapper-bg-color: var(--gl-color-light-grey) !default;
$time-progress-wrapper-border-color: transparent !default;
$time-progress-wrapper-border-radius: 0 !default;
$time-progress-wrapper-border-style: none !default;
$time-progress-wrapper-border-width: 0 !default;
$time-progress-wrapper-text-color: var(--gl-color-primary) !default;
$time-progress-wrapper-padding: 30px !default;

.gl-time-progress {
  position: relative;
  text-align: center;

  &--bordered {
    border: $time-progress-border-width solid $time-progress-border-color;
  }

  &__wrapper {
    background-color: $time-progress-wrapper-bg-color;
    border-color: $time-progress-wrapper-border-color;
    border-radius: $time-progress-wrapper-border-radius;
    border-style: $time-progress-wrapper-border-style;
    border-width: $time-progress-wrapper-border-width;
    color: $time-progress-wrapper-text-color;
    padding: $time-progress-wrapper-padding;
    position: relative;

    p {
      margin: 10px 0 0;
    }
  }

  &__bar {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 0%;
  }

  &__content {
    position: relative;
    z-index: 1;
  }
}
