// Labels
// =============

// Floating labels
$input-label-font-size: map-fetch($fonts, 'body' 'default' 'sizes' 'default');
$input-label-text-color: var(--gl-color-dark-grey);
$input-label-background-color: var(--gl-color-secondary);
$input-label-top: 14px;

// Hoisted labels
$input-label-background-color-hoisted: var(--gl-color-secondary);
$input-label-font-size-hoisted: map-fetch($fonts, 'body' 'default' 'sizes' 's');
$input-label-text-color-hoisted: var(--gl-color-dark-grey);
$input-label-top-hoisted: -11px;
$input-label-vertical-padding-hoisted: 2px;
$input-label-horizontal-padding-hoisted: 5px;

// Inputs
// ======
$input-background-color: var(--gl-color-secondary);
$input-border-color: var(--gl-color-dark-grey);
$input-border-radius: 0;
$input-border-width: 1px;
$input-height: 50px;
$input-line-height: 20px;
$input-padding-x: 20px;
$input-padding-x-small: 10px;
$input-padding-top: 13px;
$input-padding-bottom: 13px;
$input-placeholder-color: var(--gl-color-primary);
$input-text-color: var(--gl-color-primary);
$input-font-size: map-fetch($fonts, 'body' 'default' 'sizes' 'default');
$input-text-transform: none;

// Hover & Focus
$input-border-color-hover: var(--gl-color-primary);

// Disabled
$input-border-color-disabled: var(--gl-color-dark-grey);
$input-text-color-disabled: var(--gl-color-dark-grey);
$input-enable-validation-icon: true;

// Form Hints
// ==========
$form-hint-font-size: map-fetch($fonts, 'body' 'default' 'sizes' 's');
$form-hint-line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 's');
$form-hint-font-weight: 400;
$form-hint-padding: 20px;
$form-hint-margin-top: 5px;
$form-hint-text-color: var(--gl-color-dark-grey);
$form-hint-error-font-weight: 700;
$form-hint-error-text-color: var(--gl-color-validation-error);

// Dropdowns
// =========
$dropdown-option-border-color: var(--gl-color-light-grey);
$dropdown-option-border-width: 1px;
$dropdown-option-background-color-hover: var(--gl-color-light-grey);
$dropdown-custom-select-padding-x-compact: 10px;
$dropdown-custom-select-height-compact: 30px;
$dropdown-custom-options-padding-x-compact: 0;
$dropdown-custom-option-padding-x-compact: 10px;
$dropdown-custom-option-padding-y-compact: 0;
$dropdown-custom-option-height-compact: 30px;
$dropdown-custom-option-line-height-compact: normal;

// Checkboxes
// ==========
$checkbox-background: var(--gl-color-secondary);
$checkbox-border-color: var(--gl-color-dark-grey);
$checkbox-border-radius: 0;
$checkbox-border-width: 2px;
$checkbox-size: 24px;
$checkbox-margin-right: 14px;

// Hover
$checkbox-border-color-hover: var(--gl-color-primary);

// Checked
$checkbox-background-checked: var(--gl-color-primary);
$checkbox-border-color-checked: var(--gl-color-primary);
$checkbox-check-color: var(--gl-color-secondary);

// Radio Buttons
// =============
$radio-size: $checkbox-size;
$radio-group-vertical-spacing: 20px;

// Checked
$radio-check-color: var(--gl-color-secondary);
$radio-check-size: 8px;
$radio-label-font-weight-checked: bold;

// Dates
// =============

// Icon
$date-icon-margin-top: 14px;
$date-icon-container-size: 25px;

// Column
$date-col-size-default: 60px;
$date-col-size-medium: 70px;
$date-col-gutters-medium: 10px;

// Hint
$date-form-hint-padding: $form-hint-padding;
$date-form-hint-padding-medium: $form-hint-padding - 5;

// Input
$date-input-padding-x-medium: 15px;
