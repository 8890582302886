.gl-date {
  &__container {
    padding-left: 0;
    padding-right: 0;
    position: relative;

    ~ .gl-form-hint,
    ~ .gl-form-item--error > .gl-form-hint {
      padding-left: $date-form-hint-padding-medium;

      @include gl-respond-to('large') {
        padding-left: $date-form-hint-padding;
      }
    }
  }

  &__row {
    align-content: stretch;
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: auto;

    &--icon {
      .gl-date__col {
        &:nth-child(3) {
          margin-right: $date-col-gutters-medium;
          min-width: $date-col-size-medium;

          @include gl-respond-to('large') {
            margin-right: $col-gutters;
            min-width: unset;
          }
        }
      }

      .gl-date__col--icon {
        margin: 0;
        max-width: $date-icon-container-size;
      }
    }
  }

  &__col {
    margin: $date-col-gutters-medium $date-col-gutters-medium 0 0;
    min-width: $date-col-size-default;

    @include gl-respond-to('large') {
      margin: $col-gutters $col-gutters 0 0;
    }

    &:nth-child(-n + 4) {
      margin-top: 0;
    }

    &:nth-child(1) {
      align-self: stretch;
      display: block;
      flex: 1 1 auto;
      order: 0;
    }

    &:nth-child(2) {
      align-self: stretch;
      display: block;
      flex: 1 1 auto;
      order: 0;
    }

    &:nth-child(3) {
      align-self: stretch;
      display: block;
      flex: 4 1 auto;
      margin: 0;
      order: 0;
    }

    &:nth-child(4) {
      align-self: stretch;
      flex: 0 0 auto;
      max-width: $date-icon-container-size;
      order: 0;
    }

    &--icon {
      min-width: $date-icon-container-size;
      visibility: hidden;

      .gl-date__icon {
        &--success {
          color: var(--gl-color-validation-success);
        }

        &--error {
          color: var(--gl-color-validation-error);
        }
      }
    }

    &--show {
      visibility: visible;
    }

    .gl-input__field {
      padding: $input-padding-top $date-input-padding-x-medium
        $input-padding-bottom $date-input-padding-x-medium;

      @include gl-respond-to('large') {
        padding: $input-padding-top $input-padding-x $input-padding-bottom
          $input-padding-x;
      }
    }
  }

  &__icon {
    position: absolute;
    top: $date-icon-margin-top;
  }

  &__label-wrapper {
    margin-bottom: $space-small;
  }

  .gl-input {
    margin-bottom: 0;
    min-height: 0;
  }
}
