// Buttons
// =======

// Table of Contents
// -----------------
// 1. Variables
// 2. General

// 1. Variables
// ------------

// Enable and disable features
$button-enable-icon: true !default;
$button-enable-light: true !default;
$button-border-radius: 0 !default;
$button-border-width: 1px !default;
$button-height: 50px !default;
$button-padding: 0 21px !default;
$button-text-align: left !default;
$button-width: auto !default;
$button-text-transform: uppercase !default;
$button-icon-padding-left: 20px !default;
$button-disabled-background-color: var(--gl-color-dark-grey) !default;
$button-disabled-text-color: var(--gl-color-secondary) !default;
$button-disabled-border-color: var(--gl-color-dark-grey) !default;
$button-loader-size: 24px !default;

// Primary
$primary-bg-color: var(--gl-color-primary) !default;
$primary-bg-color-hover: none !default;
$primary-text-color: var(--gl-color-secondary) !default;
$primary-text-color-hover: var(--gl-color-dark-grey) !default;
$primary-border-color: var(--gl-color-secondary) !default;
$primary-border-color-hover: var(--gl-color-dark-grey) !default;

// Primary Light
$primary-light-bg-color: var(--gl-color-white) !default;
$primary-light-bg-color-hover: none !default;
$primary-light-text-color: var(--gl-color-primary) !default;
$primary-light-text-color-hover: var(--gl-color-dark-grey) !default;
$primary-light-border-color: var(--gl-color-white) !default;
$primary-light-border-color-hover: var(--gl-color-white) !default;

// Secondary
$secondary-bg-color: var(--gl-color-secondary) !default;
$secondary-bg-color-hover: none !default;
$secondary-text-color: var(--gl-color-primary) !default;
$secondary-text-color-hover: var(--gl-color-dark-grey) !default;
$secondary-border-color: var(--gl-color-primary) !default;
$secondary-border-color-hover: var(--gl-color-primary) !default;

// Secondary Light
$secondary-light-bg-color: var(--gl-color-primary) !default;
$secondary-light-bg-color-hover: none !default;
$secondary-light-text-color: var(--gl-color-white) !default;
$secondary-light-text-color-hover: var(--gl-color-dark-grey) !default;
$secondary-light-border-color: var(--gl-color-white) !default;
$secondary-light-border-color-hover: var(--gl-color-white) !default;

// Tertiary
$tertiary-text-color: var(--gl-color-primary) !default;
$tertiary-text-color-hover: var(--gl-color-white) !default;
$tertiary-bg-color: transparent !default;
$tertiary-bg-color-hover: var(--gl-color-primary) !default;
$tertiary-padding: 2px !default;

// Tertiary Light
$tertiary-light-text-color: var(--gl-color-white) !default;
$tertiary-light-text-color-hover: var(--gl-color-primary) !default;
$tertiary-light-bg-color: transparent !default;
$tertiary-light-bg-color-hover: var(--gl-color-white) !default;

// Icon
$icon-bg-color: var(--gl-color-white) !default;
$icon-bg-color-hover: var(--gl-color-white) !default;
$icon-bg-color-disabled: var(--gl-color-white) !default;
$icon-text-color: var(--gl-color-primary) !default;
$icon-text-color-hover: var(--gl-color-dark-grey) !default;
$icon-text-color-disabled: var(--gl-color-dark-grey) !default;
$icon-border-color: var(--gl-color-primary) !default;
$icon-border-color-hover: var(--gl-color-primary) !default;
$icon-border-color-disabled: var(--gl-color-dark-grey) !default;
$icon-border-color-disabled-hover: var(--gl-color-primary) !default;

// 2. General
// ----------

.gl-cta {
  align-items: center;
  border: none;
  border-radius: $button-border-radius;
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  line-height: $button-height;
  min-height: $button-height;
  padding: $button-padding;
  position: relative;
  text-align: $button-text-align;
  text-decoration: none;
  text-transform: $button-text-transform;
  transition: transform map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default'),
    color map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default'),
    border map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default'),
    background map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
  white-space: nowrap;
  width: $button-width;

  &__content {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;

    img {
      display: block;
    }
  }

  @media (pointer: fine) {
    &:hover {
      text-decoration: none;
    }
  }

  // 3. Primary
  // ----------

  &--primary {
    background-color: $primary-bg-color;
    border-color: $primary-border-color;
    color: $primary-text-color;
    height: $button-height;

    @media (pointer: fine) {
      &:hover {
        background-color: $primary-bg-color-hover;
        border-color: $primary-border-color-hover;
        color: $primary-text-color-hover;
      }
    }
  }

  @if ($button-enable-light) {
    &--primary-light {
      background-color: $primary-light-bg-color;
      border-color: $primary-light-border-color;
      color: $primary-light-text-color;
      height: $button-height;

      @media (pointer: fine) {
        &:hover {
          background-color: $primary-light-bg-color-hover;
          border-color: $primary-light-border-color-hover;
          color: $primary-light-text-color-hover;
        }
      }
    }
  }

  &--full-width {
    justify-content: space-between;
    width: 100%;
  }

  // 4. Secondary
  // ------------

  &--secondary {
    background-color: $secondary-bg-color;
    border-color: $secondary-border-color;
    border-style: solid;
    border-width: $button-border-width;
    color: $secondary-text-color;
    height: $button-height;

    @media (pointer: fine) {
      &:hover {
        background-color: $secondary-bg-color-hover;
        border-color: $secondary-border-color-hover;
        color: $secondary-text-color-hover;
      }
    }
  }

  @if ($button-enable-light) {
    &--secondary-light {
      background-color: $secondary-light-bg-color;
      border-color: $secondary-light-border-color;
      color: $secondary-light-text-color;
      height: $button-height;

      @media (pointer: fine) {
        &:hover {
          background-color: $secondary-light-bg-color-hover;
          border-color: $secondary-light-border-color-hover;
          color: $secondary-light-text-color-hover;
        }
      }
    }
  }

  // 5. Tertiary
  // -----------

  &--tertiary {
    background-color: $tertiary-bg-color;
    color: $tertiary-text-color;
    line-height: 17px;
    min-height: 0;
    padding: $tertiary-padding;
    padding-bottom: 3px;
    text-align: $button-text-align;
    text-decoration: underline;
    white-space: unset;

    @media (pointer: fine) {
      &:hover {
        background-color: $tertiary-bg-color-hover;
        color: $tertiary-text-color-hover;
        text-decoration: underline;
      }
    }

    .gl-cta__content {
      .gl-icon {
        margin-right: 10px;
      }
    }
  }

  @if ($button-enable-light) {
    &--tertiary-light {
      background-color: $tertiary-light-bg-color;
      color: $tertiary-light-text-color;

      @media (pointer: fine) {
        &:hover {
          background-color: $tertiary-light-bg-color-hover;
          color: $tertiary-light-text-color-hover;
          text-decoration: underline;
        }
      }
    }
  }

  // 6. Icon
  // -------

  @if ($button-enable-icon) {
    &--icon {
      align-items: center;
      background-color: $icon-bg-color;
      border-color: $icon-border-color;
      border-style: solid;
      border-width: $button-border-width;
      color: $icon-text-color;
      cursor: pointer;
      display: flex;
      height: $button-height;
      justify-content: center;
      letter-spacing: 0;

      // To center icon
      line-height: ($button-height - 3px);
      padding: 0;
      width: $button-height;

      &.gl-cta--no-border {
        border: 0;
      }

      @media (pointer: fine) {
        &:hover {
          background-color: $icon-bg-color-hover;
          border-color: $icon-border-color-hover;
          color: $icon-text-color-hover;
        }
      }

      &.gl-cta--disabled {
        background-color: $icon-bg-color-disabled;
        border-color: $icon-border-color-disabled;
        color: $icon-text-color-disabled;

        &:hover {
          border-color: $icon-border-color-disabled-hover;
        }
      }

      .gl-cta__icon {
        margin: 0;
      }

      .gl-cta__loading-spinner {
        margin-left: 0;
      }
    }

    &__icon {
      flex-shrink: 0;
      margin-left: $button-icon-padding-left;

      &--standalone {
        margin-left: 0;
      }
    }
  } @else {
    &__icon {
      display: none !important;
    }
  }

  // 7. Loader
  // ---------

  &--loading {
    align-items: center;

    .gl-cta__icon {
      display: none;
    }
  }

  &__loading-spinner {
    align-items: center;
    background: none;
    border-color: transparent transparent transparent currentColor;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    display: none;
    height: $button-loader-size;
    justify-content: center;
    margin: 0 0 0 $button-icon-padding-left;
    transform: translateZ(0);
    vertical-align: middle;
    width: $button-loader-size;

    .gl-cta--loading & {
      display: inline-flex;
    }

    &--standalone {
      margin-left: 0;
    }
  }

  // 8. Disabled
  // -----------

  &--disabled {
    &,
    &:hover,
    &:active,
    &:focus {
      background-color: $button-disabled-background-color;
      border-color: $button-disabled-border-color;
      color: $button-disabled-text-color;
      cursor: not-allowed;
    }
  }
}
