.gl-price {
  display: flex;
  flex-wrap: wrap;
  font-family: $font-price-family;
  font-size: map-fetch($fonts, 'prices' 'sizes' 'default');
  font-weight: $font-price-weight;

  &--horizontal {
    align-items: center;
  }

  &--vertical {
    flex-direction: column;

    .gl-price-item {
      margin-bottom: 5px;
      margin-right: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &-item {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    &--membership {
      text-transform: uppercase;
    }

    &--crossed {
      color: $font-price-crossed-color;
      text-decoration: line-through;
    }

    &--sale {
      color: $font-price-sale-color;
    }

    &--small {
      font-size: map-fetch($fonts, 'prices' 'sizes' 's');
    }
  }
}
