/**
* General Typography Configuration
*
* 1. Configuration
* 2. Variables Assignments
* 3. Mappings
*/

/**
* 1. Configuration
*/

// Declarations
$fonts-fallback: 'Helvetica', 'Arial', sans-serif;
$fonts: (
  availablefonts: (
    adihausdin: (
      fontname: 'AdihausDIN',
      fontdisplay: swap,
      styles: (
        regular: (
          file: 'AdihausDIN-Regular',
          weight: normal,
          style: normal
        ),
        bold: (
          file: 'AdihausDIN-Bold',
          weight: 700,
          style: normal
        )
      )
    ),
    adihausdin-cn: (
      fontname: 'AdihausDIN Cn',
      fontdisplay: swap,
      styles: (
        italic: (
          file: 'AdihausDIN-CnMediumItalic',
          weight: 500,
          style: normal
        )
      )
    ),
    adineuepro: (
      fontname: 'AdineuePRO',
      fontdisplay: swap,
      styles: (
        regular: (
          file: 'adineuePRO-Regular',
          weight: 400,
          style: normal
        ),
        bold: (
          file: 'adineuePRO-Bold',
          weight: 600,
          style: normal
        )
      )
    )
  ),
  headings: (
    default: (
      fontused: (
        'AdineuePRO',
        'AdihausDIN Cn'
      ),
      usage: (
        xxl: 'Page Headlines',
        xl: 'Page Headlines',
        l: 'Page Headlines',
        m: 'Page Headlines, Sub Headlines',
        s: 'Sub Headlines'
      ),
      styles: (
        default: 'bold',
        regular: true,
        bold: true,
        italic: true
      ),
      sizes: (
        desktop: (
          xxl: 66px,
          xl: 52px,
          l: 42px,
          m: 30px,
          s: 20px
        ),
        mobile: (
          xxl: 56px,
          xl: 44px,
          l: 36px,
          m: 26px,
          s: 18px
        )
      ),
      line-heights: (
        desktop: (
          xxl: 56px,
          xl: 44px,
          l: 38px,
          m: 28px,
          s: 18px
        ),
        mobile: (
          xxl: 48px,
          xl: 38px,
          l: 32px,
          m: 24px,
          s: 16px
        )
      ),
      letter-spacing: (
        desktop: (
          default: 1.5px,
          xxl: 1.5px,
          xl: 1.5px,
          l: 1.5px,
          m: 1.5px,
          s: 0.7px
        ),
        mobile: (
          default: 1.5px,
          xxl: 1.5px,
          xl: 1.5px,
          l: 1.5px,
          m: 1.5px,
          s: 0.7px
        )
      )
    ),
    storytelling: (
      fontused: (
        'AdineuePRO',
        'AdihausDIN'
      ),
      usage: (
        storytelling-xl: 'To be used as a heading in Storytelling',
        storytelling-l: 'To be used as a heading in Storytelling',
        storytelling-s: 'To be used as a sub-heading in Storytelling',
        storytelling-statement: 'To be used as a quote'
      ),
      styles: (
        default: 'bold',
        regular: true,
        bold: true
      ),
      sizes: (
        desktop: (
          storytelling-xl: 64px,
          storytelling-l: 42px,
          storytelling-s: 20px,
          storytelling-statement: 30px
        ),
        mobile: (
          storytelling-xl: 48px,
          storytelling-l: 36px,
          storytelling-s: 20px,
          storytelling-statement: 18px
        )
      ),
      line-heights: (
        desktop: (
          storytelling-xl: 77px,
          storytelling-l: 50px,
          storytelling-s: 24px,
          storytelling-statement: 54px
        ),
        mobile: (
          storytelling-xl: 60px,
          storytelling-l: 43px,
          storytelling-s: 24px,
          storytelling-statement: 36px
        )
      ),
      letter-spacing: (
        desktop: (
          storytelling-xl: 9px,
          storytelling-l: 3px,
          storytelling-s: 2.8px,
          storytelling-statement: 6px
        ),
        mobile: (
          storytelling-xl: 6px,
          storytelling-l: 2.6px,
          storytelling-s: 2.8px,
          storytelling-statement: 3px
        )
      )
    )
  ),
  body: (
    default: (
      fontused: (
        'AdihausDIN'
      ),
      styles: (
        default: 'regular',
        regular: true,
        bold: true
      ),
      usage: (
        l: 'Bodytext (enhanced content) text fields',
        default: 'Bodytext (basic content)',
        s: 'Bodytext (minimum size), captions',
        xs: 'Legal copy, Smart app banners'
      ),
      sizes: (
        l: 18px,
        default: 16px,
        s: 14px,
        xs: 12px
      ),
      line-heights: (
        l: 24px,
        default: 22px,
        s: 20px,
        xs: 16px
      ),
      margins: (
        l: 18px,
        default: 16px,
        s: 14px,
        xs: 12px
      )
    ),
    storytelling: (
      fontused: (
        'AdihausDIN'
      ),
      styles: (
        default: 'bold',
        regular: true,
        bold: true
      ),
      usage: (
        storytelling-xl: 'To be used as a body copy in Storytelling',
        storytelling-l: 'To be used as a body copy in Storytelling'
      ),
      sizes: (
        storytelling-xl: 24px,
        storytelling-l: 20px
      ),
      line-heights: (
        storytelling-xl: 36px,
        storytelling-l: 30px
      )
    )
  ),
  prices: (
    fontused: (
      'AdihausDIN'
    ),
    styles: (
      default: 'regular',
      regular: true,
      bold: true
    ),
    usage: (
      default: 'Product Detail Page or other appropriate context',
      s: 'Product card or other appropriate context'
    ),
    sizes: (
      default: 16px,
      s: 12px
    ),
    line-heights: (
      default: 22px,
      s: 16px
    )
  ),
  labels: (
    fontused: (
      'AdihausDIN'
    ),
    styles: (
      default: 'regular',
      regular: true,
      bold: true
    ),
    usage: (
      condensed: 'Product Cards or other appropriate context',
      xl: 'Header',
      l: 'Buttons',
      m: 'Labels, badges or other appropriate context',
      s: 'Labels, badges or other appropriate context',
      xs: 'Smart app banners only'
    ),
    sizes: (
      condensed: 16px,
      xl: 14px,
      l: 13px,
      m: 12px,
      s: 11px,
      xs: 9px
    ),
    line-heights: (
      condensed: 16px,
      xl: 18px,
      l: 17px,
      m: 16px,
      s: 15px,
      xs: 13px
    ),
    letter-spacing: (
      default: 2px,
      condensed: 0.5px
    )
  )
);

/**
* 2. Variables Assignments
* 2.1 Body
*/
$font-body-family: map-fetch($fonts, 'availablefonts' 'adihausdin' 'fontname'),
  #{$fonts-fallback};
$font-body-weight: map-fetch(
  $fonts,
  'availablefonts' 'adihausdin' 'styles' 'regular' 'weight'
);
$font-body-style: map-fetch(
  $fonts,
  'availablefonts' 'adihausdin' 'styles' 'regular' 'style'
);

// Bold
$font-body-family--bold: map-fetch(
    $fonts,
    'availablefonts' 'adihausdin' 'fontname'
  ),
  #{$fonts-fallback};
$font-body-weight--bold: map-fetch(
  $fonts,
  'availablefonts' 'adihausdin' 'styles' 'bold' 'weight'
);
$font-body-style--bold: map-fetch(
  $fonts,
  'availablefonts' 'adihausdin' 'styles' 'bold' 'style'
);
$body-text-transform: none;

/**
* 2.2 Headings
*/
$font-heading-family: map-fetch(
    $fonts,
    'availablefonts' 'adineuepro' 'fontname'
  ),
  #{$fonts-fallback};
$font-heading-weight: map-fetch(
  $fonts,
  'availablefonts' 'adineuepro' 'styles' 'bold' 'weight'
);
$font-heading-style: map-fetch(
  $fonts,
  'availablefonts' 'adineuepro' 'styles' 'bold' 'style'
);

/**
* 2.2.1 Headings Variants
*/

// Light variant is adineuepro regular
$font-heading-family--light: map-fetch(
    $fonts,
    'availablefonts' 'adineuepro' 'fontname'
  ),
  #{$fonts-fallback};
$font-heading-weight--light: map-fetch(
  $fonts,
  'availablefonts' 'adineuepro' 'styles' 'regular' 'weight'
);
$font-heading-style--light: map-fetch(
  $fonts,
  'availablefonts' 'adineuepro' 'styles' 'regular' 'style'
);

// Light variant is adihausDIN Cn italic
$font-heading-family--italic: map-fetch(
    $fonts,
    'availablefonts' 'adihausdin-cn' 'fontname'
  ),
  #{$fonts-fallback};
$font-heading-weight--italic: map-fetch(
  $fonts,
  'availablefonts' 'adihausdin-cn' 'styles' 'italic' 'weight'
);
$font-heading-style--italic: map-fetch(
  $fonts,
  'availablefonts' 'adihausdin-cn' 'styles' 'italic' 'style'
);

/**
* 2.3 Prices
*/
$font-price-family: map-fetch($fonts, 'availablefonts' 'adihausdin' 'fontname'),
  #{$fonts-fallback};
$font-price-weight: map-fetch(
  $fonts,
  'availablefonts' 'adihausdin' 'styles' 'regular' 'weight'
);
$font-price-style: map-fetch(
  $fonts,
  'availablefonts' 'adihausdin' 'styles' 'regular' 'style'
);
$font-price-weight-bold: map-fetch(
  $fonts,
  'availablefonts' 'adihausdin' 'styles' 'bold' 'weight'
);

/**
* 2.4 Labels
*/
$font-label-family: map-fetch($fonts, 'availablefonts' 'adihausdin' 'fontname'),
  #{$fonts-fallback};
$font-label-weight: map-fetch(
  $fonts,
  'availablefonts' 'adihausdin' 'styles' 'regular' 'weight'
);
$font-label-style: map-fetch(
  $fonts,
  'availablefonts' 'adihausdin' 'styles' 'regular' 'style'
);
$font-label-family--bold: map-fetch(
    $fonts,
    'availablefonts' 'adihausdin' 'fontname'
  ),
  #{$fonts-fallback};
$font-label-weight--bold: 700;
$font-label-style--bold: normal;
$font-label-letter-spacing: map-fetch(
  $fonts,
  'labels' 'letter-spacing' 'default'
);
$font-label-letter-spacing-condensed: map-fetch(
  $fonts,
  'labels' 'letter-spacing' 'condensed'
);

/**
* 3. Mappings
*/
$font-heading-primary-family: $font-heading-family;
$font-heading-primary-weight: $font-heading-weight;
$font-heading-primary-style: $font-heading-style;
$font-heading-secondary-family: $font-heading-family--light;
$font-heading-secondary-weight: $font-heading-weight--light;
$font-heading-secondary-style: $font-heading-style--light;
$font-price-value-color: var(--gl-color-primary);
$font-price-crossed-color: var(--gl-color-dark-grey);
$font-price-sale-color: var(--gl-color-red);
$font-primary-color: var(--gl-color-primary);

// Headings with shadow
$heading-enable-shadow: true;
$heading-margin-bottom: $space-small;

// Lists
$list-item-margin-left: 31px;
$list-item-padding-left: 6px;
$list-item-ordered-margin-left: $list-item-margin-left;
$list-item-ordered-padding-left: $list-item-padding-left;
