/* stylelint-disable declaration-no-important  */

/**
 * General Helpers
 * 1. Visibility
 * 2. Text Alignment
 * 3. Vertical spacing
 * 4. No margin
 * 5. Screen readers
 * 6. Flexbox
 * 7. Effects
 */

/**
 * 1. Visibility
 */

/**
 * Use `.gl-hidden-s` to hide any element in small devices such as mobiles.
 */

.gl-hidden-s {
  @include gl-respond-to(
    null,
    'max-width',
    #{map-get($breakpoints, 'medium') - 1}
  ) {
    display: none !important;
  }
}

/**
 * Use `.gl-hidden-s-m` to hide any element in small and medium devices such as mobiles and tablets.
 */

.gl-hidden-s-m {
  @include gl-respond-to(
    null,
    'max-width',
    #{map-get($breakpoints, 'large') - 1}
  ) {
    display: none !important;
  }
}

/**
 * Use `.gl-hidden-m` to hide any element on medium devices such as tablets.
 */

.gl-hidden-m {
  @include gl-respond-to('medium') {
    display: none !important;
  }
}

/**
 * Use `.gl-hidden-l` to hide any element on large devices such as desktops.
 */

.gl-hidden-l {
  @include gl-respond-to('large') {
    display: none !important;
  }
}

/**
 * Use `.gl-hidden-xl` to hide any element on extra large devices such as desktops.
 */

.gl-hidden-xl {
  @include gl-respond-to('extra-large') {
    display: none !important;
  }
}

/**
 * Use `.gl-hidden-hg` to hide any element on huge devices such as uncommon desktop sizes.
 */

.gl-hidden-hg {
  @include gl-respond-to('huge') {
    display: none !important;
  }
}

/**
 * 2. Positioning
 */

.gl-text {
  &-center {
    text-align: center;
  }

  &-start {
    text-align: left;
    text-align: start; /* stylelint-disable-line declaration-block-no-duplicate-properties */
  }

  &-end {
    text-align: right;
    text-align: end; /* stylelint-disable-line declaration-block-no-duplicate-properties */
  }
}

/**
 * 3. Vertical spacing
 */

.gl-vspace {
  margin-top: $space;

  // Mixin to provide vertical spaces
  @mixin vspace-automate {
    &-null {
      margin-top: 0;
    }

    &-small {
      margin-top: $space-small;
    }

    &-medium {
      margin-top: $space-medium;
    }

    &-large {
      margin-top: $space-large;
    }
  }

  &-bpall {
    @include vspace-automate();
  }

  // Breakpoints provided based on `gl-config-breakpoints.scss`
  &-bpm {
    @include gl-respond-to('medium') {
      @include vspace-automate();
    }
  }

  &-bpl {
    @include gl-respond-to('large') {
      @include vspace-automate();
    }
  }

  &-bxl {
    @include gl-respond-to('extra-large') {
      @include vspace-automate();
    }
  }

  &-bhg {
    @include gl-respond-to('huge') {
      @include vspace-automate();
    }
  }
}

/**
* 4. No margin
*/

.gl-no-margin-bottom {
  margin-bottom: 0 !important;
}

/**
 * 5. screen readers
 * Only display content to screen readers
 * See: https://a11yproject.com/posts/how-to-hide-content/
 */

.gl-screen-reader-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/**
* 6. Flexbox
*/

.gl-flex {
  /** Behaviour **/

  &-display {
    display: flex;

    &-inline {
      display: inline-flex;
    }
  }

  /** Direction **/

  &-row {
    flex-direction: row;

    &-reverse {
      flex-direction: row-reverse;
    }
  }

  &-col {
    flex-direction: column;

    &-reverse {
      flex-direction: column-reverse;
    }
  }

  /** justify content **/

  &-justify-content {
    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }

    &-center {
      justify-content: center;
    }

    &-between {
      justify-content: space-between;
    }

    &-around {
      justify-content: space-around;
    }
  }

  /** align items **/

  &-align-items {
    &-start {
      align-items: flex-start;
    }

    &-end {
      align-items: flex-end;
    }

    &-center {
      align-items: center;
    }

    &-baseline {
      align-items: baseline;
    }

    &-stretch {
      align-items: stretch;
    }
  }

  /** align self **/

  &-align-self {
    &-start {
      align-self: flex-start;
    }

    &-end {
      align-items: flex-end;
    }

    &-center {
      align-items: center;
    }

    &-baseline {
      align-items: baseline;
    }

    &-stretch {
      align-items: stretch;
    }
  }

  /** fill **/

  &-fill {
    flex: 1 1 auto;
  }

  /** wrap **/

  &-wrap {
    flex-wrap: wrap;

    &-reverse {
      flex-wrap: wrap-reverse;
    }
  }

  &-nowrap {
    flex-wrap: nowrap;
  }

  /** align content **/
  &-align-content {
    &-start {
      align-content: flex-start;
    }

    &-end {
      align-content: flex-end;
    }

    &-center {
      align-content: center;
    }

    &-between {
      align-content: space-between;
    }

    &-around {
      align-content: space-around;
    }

    &-stretch {
      align-content: stretch;
    }
  }
}

/**
* 7. Effects
*/

.gl-strikethrough {
  text-decoration: line-through;
}
