/**
 * General Grid Specification Styles
 * 1. Container
 * 2. Rows
 * 3. Columns
 *  3.1. Base Columns
 *  3.2. Medium Columns
 *  3.3. Large Columns
 *  3.4. Huge Columns
 * 4. Offsets
 *  4.1. Base Offsets
 *  4.2. Medium Offsets
 *  4.3. Large Offsets
 *  4.4. Huge Offsets
 * 5. No Gutters
 * 6. Vertical Alignments
 * 7. Stack Alignments
 */

*,
*:after,
*:before {
  box-sizing: border-box;
}

/**
 * 1. Container
 */

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  position: relative;
  width: 100%;

  @include gl-respond-to('large') {
    padding-left: $col-gutters;
    padding-right: $col-gutters;
  }

  @include gl-respond-to('extra-large') {
    width: $grid-w-xl;
  }

  @include gl-respond-to('huge') {
    width: $grid-w-hg;
  }
}

/**
 * 2. Rows
 */

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

/**
 * 3. Columns
 */

[class*='col-s'],
[class*='col-m'],
[class*='col-l'],
[class*='col-xl'],
[class*='col-hg'] {
  float: left;
  padding: 0 $col-gutters;
}

/* stylelint-disable declaration-no-important  */

/**
 * 3.1. Base Columns
 */

@for $i from 1 through $columns-mob {
  .col-s-#{$i} {
    width: 100% / $columns-mob * $i;
  }
}

/**
 * 3.2. Medium Columns
 */

@include gl-respond-to('medium') {
  @for $i from 1 through $columns-mob {
    .col-m-#{$i} {
      width: 100% / $columns-mob * $i;
    }
  }
}

/**
 * 3.3. Large Columns
 */

@include gl-respond-to('large') {
  @for $i from 1 through $columns-dt {
    .col-l-#{$i} {
      width: 100% / $columns-dt * $i;
    }
  }
}

/**
 * 3.4. Extra large Columns
 */

@include gl-respond-to('extra-large') {
  @for $i from 1 through $columns-dt {
    .col-xl-#{$i} {
      width: 100% / $columns-dt * $i;
    }
  }
}

/**
 * 3.5. Huge Columns
 */

@include gl-respond-to('huge') {
  @for $i from 1 through $columns-dt {
    .col-hg-#{$i} {
      width: 100% / $columns-dt * $i;
    }
  }
}

/**
 * 4. Offsets
 */

/**
 * 4.1. Base Offsets
 */

.offset-s-0 {
  margin-left: 0;
}

@for $i from 1 through $columns-mob {
  .offset-s-#{$i} {
    margin-left: 100% / $columns-mob * $i;
  }
}

/**
 * 4.2. Medium Offsets
 */

@include gl-respond-to('medium') {
  .offset-m-0 {
    margin-left: 0;
  }

  @for $i from 1 through $columns-mob {
    .offset-m-#{$i} {
      margin-left: 100% / $columns-mob * $i;
    }
  }
}

/**
 * 4.3. Large Offsets
 */

@include gl-respond-to('large') {
  .offset-l-0 {
    margin-left: 0;
  }

  @for $i from 1 through $columns-dt {
    .offset-l-#{$i} {
      margin-left: 100% / $columns-dt * $i;
    }
  }
}

/**
 * 4.4. Extra large Offsets
 */

@include gl-respond-to('extra-large') {
  .offset-xl-0 {
    margin-left: 0;
  }

  @for $i from 1 through $columns-dt {
    .offset-xl-#{$i} {
      margin-left: 100% / $columns-dt * $i;
    }
  }
}

/**
 * 4.5. Huge Offsets
 */

@include gl-respond-to('huge') {
  .offset-hg-0 {
    margin-left: 0;
  }

  @for $i from 1 through $columns-dt {
    .offset-hg-#{$i} {
      margin-left: 100% / $columns-dt * $i;
    }
  }
}

/**
 * 5. No Gutters
 */

.no-gutters,
.no-gutters-s,
.no-gutters-l {
  padding-left: 0;
  padding-right: 0;

  > .col,
  > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
}

.no-gutters-s {
  > .col,
  > [class*='col-'] {
    @include gl-respond-to('medium') {
      padding-left: $col-gutters;
      padding-right: $col-gutters;
    }
  }
}

.no-gutters-l {
  > .col,
  > [class*='col-'] {
    @include gl-respond-to('large') {
      padding-left: $col-gutters;
      padding-right: $col-gutters;
    }
  }
}

/**
 * 6. Vertical Alignments
 */

.gl-align-items-start {
  align-items: flex-start !important;
}

.gl-align-items-center {
  align-items: center !important;
}

.gl-align-items-end {
  align-items: flex-end !important;
}
