// Loaders
// =======

// 1. Variables
// ------------
$loader-size: 90px !default;
$loader-duration-loop: 1.3s !default;
$loader-border-width: 4px !default;
$loader-border-color: currentColor !default;
$loader-radian: 90deg !default;

// 2. Styles
// ---------

.gl-loader {
  animation: gl-loader $loader-duration-loop infinite linear;
  background: transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: $loader-border-width;
  box-sizing: border-box;
  content: '';
  display: inline-block;
  height: $loader-size;
  position: relative;
  transform: translateZ(0);
  vertical-align: middle;
  width: $loader-size;
  @if ($loader-radian == 90deg) {
    border-color: transparent transparent transparent $loader-border-color;
  }
  @if ($loader-radian == 180deg) {
    border-color: transparent
      transparent
      $loader-border-color
      $loader-border-color;
  }
  @if ($loader-radian == 270deg) {
    border-color: transparent
      $loader-border-color
      $loader-border-color
      $loader-border-color;
  }
}

// 3. Keyframes
// ------------

@keyframes gl-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
