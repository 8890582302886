/* stylelint-disable declaration-property-value-blacklist, function-url-quotes */

/**
 * Forms
 *
 * 1. CORE Styles
 * 2. Shared Styles
 */

/**
 * 1. CORE Styles
 */

.gl-form {
  &__element {
    // to vertical align children to the top
    align-items: flex-start;
    display: flex;

    &--checkbox {
      display: inline-block;
      width: 100%;
    }

    &--checkbox > .gl-tooltip {
      margin-left: 6px;
      vertical-align: top;
    }

    &--checkbox > .gl-form-item--inline {
      max-width: calc(100% - 30px);
    }

    .gl-form-item {
      -ms-flex: 1; /* stylelint-disable-line */
      flex: 1;
    }
  }
}

.gl-form-item {
  position: relative;

  &--inline {
    display: inline-block;
  }
}
