$progress-step-color: var(--gl-color-dark-grey) !default;
$progress-step-font-size: map-fetch($fonts, 'labels' 'sizes' 'l') !default;
$progress-step-text-transform: uppercase !default;

.gl-progress-step {
  align-items: center;
  color: $progress-step-color;
  display: flex;
  font-family: $font-label-family--bold;
  font-size: $progress-step-font-size;
  font-weight: $font-label-weight--bold;
  letter-spacing: $font-label-letter-spacing;
  text-transform: $progress-step-text-transform;

  .gl-icon {
    height: 16px;
    margin-right: 5px;
    width: 16px;
  }

  &--active,
  &--completed {
    color: var(--gl-color-primary);
  }
}
