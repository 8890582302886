$button-height: 50px !default;
$button-text-transform: inherit !default;

.gl-file-uploader {
  &__label {
    display: block;
  }

  &__block {
    align-items: center;
    display: flex;
    height: $button-height;
    margin-bottom: 10px;
    margin-top: $space;
    width: 100%;

    &--error {
      border-bottom-color: var(--gl-color-validation-error);
      box-shadow: 0 1px var(--gl-color-validation-error);

      &:hover {
        border-bottom-color: var(--gl-color-validation-error) !important;
      }
    }
  }

  &__text {
    flex-grow: 1;
    padding-right: 20px;
    text-transform: $button-text-transform;
  }

  &__input {
    display: none;
  }

  &__list {
    margin-top: $space;
    max-width: 600px;
  }

  &__list-item {
    align-items: stretch;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }

    span {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      cursor: pointer;
      flex-shrink: 0;
    }
  }
}
