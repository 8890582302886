$modal-overlay-background-color: rgba(0, 0, 0, 0.7) !default;
$modal-background-color: var(--gl-color-secondary) !default;
$modal-border-width: 1px !default;
$modal-border-color: var(--gl-color-primary) !default;
$modal-border-radius: 0 !default;
$modal-padding-x: 20px !default;
$modal-padding-large-x: 20px !default;
$modal-padding-y: 30px !default;
$modal-padding-large-y: 20px !default;
$modal-width: 1200px !default;
$modal-close-top: -24px !default;
$modal-close-right: -24px !default;
$modal-close-background-color: var(--gl-color-secondary) !default;
$modal-close-border-color: var(--gl-color-primary) !default;
$modal-close-border-width: 1px !default;
$modal-close-color: var(--gl-color-primary) !default;
$modal-close-size: 50px !default;
$modal-mobile-full-close-top: -$modal-close-size/2 !default;
$modal-mobile-full-close-right: 15px !default;

.gl-modal {
  align-items: flex-end;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  touch-action: none;
  transform: translateZ(0);
  transition: opacity 0s linear
      (
        map-fetch($motion, 'duration' 'default') +
          map-fetch($motion, 'duration' 'fast')
      ),
    visibility 0s linear
      (
        map-fetch($motion, 'duration' 'default') +
          map-fetch($motion, 'duration' 'fast')
      );
  visibility: hidden;
  width: 100vw;
  z-index: 6;

  @include gl-respond-to('large') {
    align-items: center;
    justify-content: center;
  }

  &--no-animation {
    opacity: 1;
    visibility: visible;

    .gl-modal__overlay,
    .gl-modal__dialog {
      opacity: 1;
      visibility: visible;
    }
  }

  &__overlay {
    background-color: $modal-overlay-background-color;
    bottom: 0;
    cursor: pointer;
    display: block;
    left: 0;
    opacity: 0;
    pointer-events: all;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity map-fetch($motion, 'duration' 'default')
        map-fetch($motion, 'ease' 'default')
        map-fetch($motion, 'duration' 'fast'),
      visibility 0s linear
        (
          map-fetch($motion, 'duration' 'default') +
            map-fetch($motion, 'duration' 'fast')
        );
    visibility: hidden;
    width: 100%;
  }

  &__dialog,
  &__main {
    max-height: 80vh;

    @include gl-respond-to('large') {
      max-height: 92vh;
    }

    &:focus {
      outline: none;
    }
  }

  &__dialog {
    max-width: 90vw;
    opacity: 0;
    pointer-events: all;
    position: relative;
    transition: opacity map-fetch($motion, 'duration' 'default')
        map-fetch($motion, 'ease' 'default'),
      visibility 0s linear map-fetch($motion, 'duration' 'default');
    visibility: hidden;

    @include gl-respond-to('large') {
      margin-left: 30px;
      margin-right: 30px;
      width: 100% / $columns-dt * 12;

      &--large {
        width: 100% / $columns-dt * 17;
      }

      &--small {
        width: 100% / $columns-dt * 8;
      }
    }
  }

  &__main {
    background-color: $modal-background-color;
    border-right: $modal-border-width solid $modal-border-color;
    border-top: $modal-border-width solid $modal-border-color;
    border-top-right-radius: $modal-border-radius;
    font-size: map-fetch($fonts, 'body' 'default' 'sizes' 's');
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;

    @include gl-respond-to('large') {
      border-bottom: $modal-border-width solid $modal-border-color;
      border-left: $modal-border-width solid $modal-border-color;
      border-radius: $modal-border-radius;
    }

    &--with-top-img {
      img {
        width: 100%;
      }
    }

    &--with-left-img,
    &--with-right-img {
      display: flex;

      .gl-modal__main-img {
        background-position: center center;
        background-size: cover;
        display: none;

        @include gl-respond-to('large') {
          display: block;
          width: 30%;
        }
      }

      .gl-modal__main-content {
        @include gl-respond-to('large') {
          width: 70%;
        }
      }
    }

    &--with-left-img {
      flex-flow: row;
    }

    &--with-right-img {
      flex-flow: row-reverse;
    }

    &--with-full-img {
      .gl-modal__main-img {
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 4;

        picture {
          display: flex;
          height: 100%;
          width: 100%;

          img {
            height: auto;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }
  }

  &__main-content {
    padding: $modal-padding-y $modal-padding-x;

    @include gl-respond-to('large') {
      padding: $modal-padding-large-y $modal-padding-large-x;
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    .gl-modal__main--with-full-img & {
      position: relative;
      z-index: 5;
    }

    &--no-inner-padding {
      padding: 0;
    }
  }

  &__title {
    padding-right: 15px;

    @include gl-respond-to('large') {
      padding-right: auto;
    }
  }

  &__close {
    align-items: center;
    background-color: $modal-close-background-color;
    border: $modal-close-border-width solid $modal-close-border-color;
    color: $modal-close-color;
    cursor: pointer;
    display: flex;
    height: $modal-close-size;
    justify-content: center;
    position: absolute;
    right: $modal-close-right;
    top: $modal-close-top;
    transition: transform map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
    width: $modal-close-size;
    z-index: 5;

    &:active {
      transform: scale(0.94);
    }

    &:focus {
      outline: none;
    }
  }

  &--active {
    opacity: 1;
    transition: opacity 0s, visibility 0s;
    visibility: visible;
  }

  &--active & {
    &__overlay {
      opacity: 1;
      transition: opacity map-fetch($motion, 'duration' 'default')
          map-fetch($motion, 'ease' 'default'),
        visibility 0s;
      visibility: visible;

      &:focus {
        outline: none;
      }
    }

    &__dialog {
      opacity: 1;
      transition: opacity map-fetch($motion, 'duration' 'slow')
          map-fetch($motion, 'ease' 'default')
          map-fetch($motion, 'duration' 'fast'),
        visibility 0s;
      visibility: visible;
    }
  }

  &--small & {
    &__main,
    &__dialog {
      @include gl-respond-to('large') {
        max-height: 80vh;
        max-width: 450px;
      }
    }
  }

  &--large & {
    &__main,
    &__dialog {
      @include gl-respond-to('large') {
        max-height: 80vh;
      }
    }

    &__dialog {
      @include gl-respond-to('large') {
        max-width: $modal-width;
      }
    }
  }

  &--mobile-full & {
    @include gl-respond-to('large', 'max-width') {
      &__dialog {
        bottom: 0;
        height: auto;
        max-width: 100vw;

        /* Fixed position makes sure the 100% height accounts for the URL bar on mobile Chrome,
        * instead of flowing under the bottom of the screen.
        * https://developers.google.com/web/updates/2016/12/url-bar-resizing
        */
        position: fixed;
        width: 100%;
      }

      &__main {
        border-right: 0;
        border-top-right-radius: 0;
      }

      &__close {
        right: $modal-mobile-full-close-right;
        top: $modal-mobile-full-close-top;
      }
    }
  }

  &--left &,
  &--right & {
    &__dialog {
      margin-left: 0;
      margin-right: 0;
    }

    &__main {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &--left {
    align-items: flex-end;
    justify-content: flex-start;
  }

  &--left & {
    &__main {
      border-left: 0;
    }
  }

  &--right {
    align-items: flex-end;
    justify-content: flex-end;
  }

  &--right & {
    &__main {
      border-left: $modal-border-width solid $modal-border-color;
      border-right: 0;
      border-top-left-radius: $modal-border-radius;
      border-top-right-radius: 0;
    }

    &__close {
      left: $modal-close-right;
      right: auto;
    }
  }

  .container {
    @include gl-respond-to('large') {
      padding: 0;
    }
  }

  &__section {
    &--no-horizontal-padding {
      margin-left: -$modal-padding-x;
      margin-right: -$modal-padding-x;

      @include gl-respond-to('large') {
        margin-left: -$modal-padding-large-x;
        margin-right: -$modal-padding-large-x;
      }
    }
  }
}

body.gl-modal-open {
  overflow: hidden;
}
