$accordion-clickable-hover-color: var(--gl-color-light-grey) !default;
$accordion-border-color: var(--gl-color-light-grey) !default;
$accordion-header-height: 50px !default;
$accordion-padding: 20px !default;
$accordion-select-border-width: 4px !default;
$accordion-title-font-family: $font-label-family--bold !default;
$accordion-title-font-size: map-fetch($fonts, 'labels' 'sizes' 'l') !default;
$accordion-title-font-weight: $font-label-weight--bold !default;
$accordion-title-font-letter-spacing: $font-label-letter-spacing !default;
$accordion-title-font-text-transform: uppercase !default;
$accordion-subtitle-font-family: $font-body-family !default;
$accordion-subtitle-font-size: map-fetch(
  $fonts,
  'body' 'default' 'sizes' 's'
) !default;
$accordion-subtitle-font-weight: $font-body-weight !default;
$accordion-subtitle-font-color: var(--gl-color-primary) !default;

@mixin accordion-clickable {
  margin: -$accordion-padding;
  padding: $accordion-padding;

  &:hover {
    @media (pointer: fine) {
      background-color: $accordion-clickable-hover-color;
    }
  }
}

.gl-accordion {
  background-color: var(--gl-color-secondary);
  border: 1px solid $accordion-border-color;

  &:not(:last-child) {
    border-bottom: none;
  }

  &__header {
    align-items: stretch;
    background: none;
    border: 0;
    border-left: $accordion-select-border-width solid transparent;
    cursor: pointer;
    display: flex;
    height: $accordion-header-height;
    margin-left: -1px;
    outline: 0;
    text-align: inherit;
    width: calc(100% + 1px);

    &::-moz-focus-inner {
      border: 0;
    }

    .gl-accordion--selected:not(.gl-accordion--open) & {
      border-left-color: var(--gl-color-primary);
    }

    &-text {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      justify-content: center;
      padding-left: $accordion-padding - $accordion-select-border-width + 1px;
    }

    &-icon {
      align-items: center;
      display: flex;
      flex: 0 0 $accordion-header-height;
      height: $accordion-header-height;
      justify-content: center;
      transition: transform $gl-duration-default $gl-ease-default;
      width: $accordion-header-height;

      .gl-accordion--open & {
        transform: rotateX(180deg);
      }
    }
  }

  &__title {
    display: block;
    font-family: $accordion-title-font-family;
    font-size: $accordion-title-font-size;
    font-weight: $accordion-title-font-weight;
    letter-spacing: $accordion-title-font-letter-spacing;
    text-transform: $accordion-title-font-text-transform;
  }

  &__summary {
    color: $accordion-subtitle-font-color;
    display: block;
    font-family: $accordion-subtitle-font-family;
    font-size: $accordion-subtitle-font-size;
    font-weight: $accordion-subtitle-font-weight;

    .gl-accordion--open & {
      display: none;
    }
  }

  &__content {
    display: none;
    padding: $accordion-padding;

    .gl-accordion--open & {
      display: block;
    }

    .gl-form-item {
      cursor: pointer;
      margin: 0 (-$accordion-padding);
      padding: $accordion-padding;

      &:first-child {
        margin-top: -$accordion-padding;
      }

      &:last-child {
        margin-bottom: -$accordion-padding;
      }

      // Hide radio group label
      > .gl-label {
        display: none;
      }

      .gl-checkbox {
        &__label {
          display: flex;
          @include accordion-clickable();
        }

        &__icon {
          left: $accordion-padding;
          top: $accordion-padding;
        }
      }

      .gl-radio-input {
        &__option {
          display: flex;
          @include accordion-clickable();
        }
      }
    }
  }
}
