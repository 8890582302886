$tooltip-text-align: initial !default;
$tooltip-width: 290px !default;
$tooltip-small-width: 200px !default;
$tooltip-large-width: 500px !default;
$tooltip-text-color-light: var(--gl-color-primary) !default;
$tooltip-bg-color-light: var(--gl-color-light-grey) !default;
$tooltip-text-color-dark: var(--gl-color-light-grey) !default;
$tooltip-bg-color-dark: var(--gl-color-primary) !default;
$tooltip-border-width: 0 !default;
$tooltip-border-color: transparent !default;
$tooltip-border-radius: 0 !default;
$tooltip-icon-background-color: var(--gl-color-light-grey) !default;
$tooltip-icon-color: var(--gl-color-primary) !default;
$tooltip-icon-color-hover: var(--gl-color-dark-grey) !default;
$tooltip-icon-size: 24px !default;
$tooltip-arrow-angle: 60deg !default;
$tooltip-arrow-height: 20px !default;
$tooltip-arrow-width: 20px !default;
$tooltip-arrow-offset: -$tooltip-arrow-width / 2 - $tooltip-border-width !default;
$tooltip-close-background-color: transparent !default;
$tooltip-close-color-light: var(--gl-color-secondary) !default;
$tooltip-close-color-dark: var(--gl-color-primary) !default;

.gl-tooltip {
  display: inline-block;
  position: relative;

  &__panel {
    background-color: $tooltip-bg-color-light;
    border: solid $tooltip-border-width $tooltip-border-color;
    border-radius: $tooltip-border-radius;
    color: $tooltip-text-color-light;
    max-width: calc(100vw - 40px);
    position: absolute;
    text-align: $tooltip-text-align;
    width: $tooltip-width;
    z-index: 2;

    &--small {
      width: $tooltip-small-width;
    }

    &--large {
      width: $tooltip-large-width;
    }

    &--dark {
      background-color: $tooltip-bg-color-dark;
      color: $tooltip-text-color-dark;
    }
  }

  &__arrow {
    $skew-angle: 45deg - $tooltip-arrow-angle / 2;

    background: $tooltip-bg-color-light;
    border: solid $tooltip-border-width $tooltip-border-color;
    height: $tooltip-arrow-height;
    overflow: hidden;
    position: absolute;
    width: $tooltip-arrow-width;
    z-index: 3;

    &--right {
      left: $tooltip-arrow-offset;
      transform: rotate(-45deg) skew($skew-angle, $skew-angle);
    }

    &--left {
      right: $tooltip-arrow-offset;
      transform: rotate(135deg) skew($skew-angle, $skew-angle);
    }

    &--top {
      bottom: $tooltip-arrow-offset;
      transform: rotate(225deg) skew($skew-angle, $skew-angle);
    }

    &--bottom {
      top: $tooltip-arrow-offset;
      transform: rotate(45deg) skew($skew-angle, $skew-angle);
    }

    &--dark {
      background: $tooltip-bg-color-dark;
    }

    @include gl-safari-only() {
      will-change: transform;
    }
  }

  &__inner {
    display: inline-block;
    min-width: 200px;
    padding: 20px;

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    p {
      line-height: 20px;
      margin-bottom: 0;
    }
  }

  &__title,
  h5 {
    @include gl-heading-style('s');
    @include gl-body-style(null, 'bold');

    letter-spacing: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  &__button {
    // We need to support having the Tooltip component inside <h5> (which has its
    // own letter-spacing), so clearing the letter-spacing here.
    // stylelint-disable-next-line declaration-property-value-blacklist
    border: none;
    letter-spacing: 0;

    &:focus {
      outline: none;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &--with-icon &__button {
    align-items: center;
    background-color: $tooltip-icon-background-color;
    color: $tooltip-icon-color;
    cursor: pointer;
    display: inline-flex;
    height: $tooltip-icon-size;
    justify-content: center;
    margin-bottom: 0;
    padding: 2px;
    transition: color map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
    width: $tooltip-icon-size;

    &:focus {
      color: $tooltip-icon-color-hover;
    }

    @media (pointer: fine) {
      &:hover {
        color: $tooltip-icon-color-hover;
      }
    }

    &--dark {
      background-color: $tooltip-bg-color-dark;
      border-color: $tooltip-bg-color-dark;

      svg {
        background-color: $tooltip-bg-color-dark;
        color: $tooltip-text-color-dark;
      }
    }
  }

  &__icon {
    max-height: 100%;
    max-width: 100%;
  }

  &__close {
    align-items: center;
    background-color: $tooltip-close-background-color;
    color: $tooltip-close-color-dark;
    cursor: pointer;
    float: right;
    transition: transform map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
    z-index: 5;
    margin: {
      right: 10px;
      top: 10px;
    }

    &:active {
      transform: scale(0.94);
    }

    &--dark {
      color: $tooltip-close-color-light;
    }
  }
}
