$icon-size: 24px !default;
$communication-icon-size: 19px !default;

.gl-icon {
  display: inline-block;
  height: $icon-size;
  vertical-align: text-top;
  width: $icon-size;

  &--light {
    color: var(--gl-color-white);
  }

  &--circle {
    border-radius: 50%;
    padding: 3px;
  }

  &--size-communication {
    height: $communication-icon-size;
    width: $communication-icon-size;
  }
}
