$filter-icon-size: 13px !default;
$filter-border: 1px solid var(--gl-color-medium-grey) !default;

.gl-filter {
  align-items: center;
  background-color: var(--gl-color-secondary);
  border: $filter-border;
  cursor: pointer;
  display: inline-flex;
  font-size: map-fetch($fonts, 'body' 'default' 'sizes' 's');
  padding: $space;

  &__icon {
    height: $filter-icon-size;
    margin-right: $space / 2;
    width: $filter-icon-size;
  }

  &__label {
    &::before {
      content: attr(data-label);
      display: block;
      font-weight: $font-body-weight--bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  &:hover {
    border-color: var(--gl-color-primary);
  }

  &--active {
    background-color: var(--gl-color-primary);
    color: var(--gl-color-secondary);
    font-weight: $font-body-weight--bold;
  }
}
