@mixin input-field-bottom($bottom-color) {
  border-bottom: $input-border-width solid $bottom-color;
  box-shadow: 0 1px 0 0 $bottom-color;
}

@mixin input-hoisted-label() {
  background-color: $input-label-background-color-hoisted;
  color: $input-label-text-color-hoisted;
  font-size: $input-label-font-size-hoisted;
  left: $input-padding-x - $input-label-horizontal-padding-hoisted;
  padding: $input-label-vertical-padding-hoisted
    $input-label-horizontal-padding-hoisted;
  top: $input-label-top-hoisted;
}

.gl-textarea {
  position: relative;

  &__field {
    @include gl-body-style();

    background: $input-background-color;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    box-sizing: border-box;
    color: $input-text-color;
    font-size: map-fetch($fonts, 'body' 'default' 'sizes' 'default');
    line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 'default');
    margin: 0;
    padding: $input-padding-top $input-padding-x $input-padding-bottom
      $input-padding-x;
    resize: none;
    text-transform: $input-text-transform;
    transition: color map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-size: 13px;
      letter-spacing: 2px;
      opacity: 1; /* Firefox */
      text-transform: uppercase;
    }

    /* stylelint-disable */
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder,
    &:-moz-placeholder {
      /* stylelint-enable */
      color: $input-placeholder-color;
      font-size: 13px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  &__label {
    color: $input-label-text-color;
    display: flex;
    font-size: $input-label-font-size;
    left: $input-padding-x;
    pointer-events: none;
    position: absolute;
    top: $input-label-top;
    transition: 0.2s ease all;

    // Hoisted for IE11 and legacy Edge
    // Work perfectly in IE11
    // In legacy Edge, when failed validation, label still overlap input text
    textarea:focus + &,
    textarea:not(:empty) + & {
      @include input-hoisted-label();
    }

    // Hoisted for modern browsers
    // Work perfectly in Firefox, Chrome and Safari
    // IE doesn't support :placeholder-shown and will ignore this block
    textarea:focus + &,
    textarea:not(:placeholder-shown) + & {
      @include input-hoisted-label();
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 10px;

    .gl-form-item--error & {
      color: var(--gl-color-validation-error);
    }

    .gl-form-item--success & {
      color: var(--gl-color-validation-success);
    }
  }
}
