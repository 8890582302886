$sizes: l, s;

.gl-list {
  font-size: map-fetch($fonts, 'body' 'default' 'sizes' 'default');
  line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 'default');
  margin: 0;
  padding: 0;

  @each $size in $sizes {
    &--#{$size} {
      font-size: map-fetch($fonts, 'body' 'default' 'sizes' #{$size});
      line-height: map-fetch($fonts, 'body' 'default' 'line-heights' #{$size});
    }
  }

  @include gl-list-style('disc');

  li,
  &__item {
    margin-left: $list-item-margin-left;
    padding-left: $list-item-padding-left;
    white-space: normal;
  }
}

.gl-list--ordered {
  @include gl-list-style('decimal');

  li,
  &__item {
    margin-left: $list-item-ordered-margin-left;
    padding-left: $list-item-ordered-padding-left;
    white-space: normal;
  }
}
