$table-cell-height: 50px !default;
$table-cell-width: 130px !default;
$table-cell-padding: 10px !default;
$table-cell-border-width: 1px !default;
$table-cell-line-height: 15px !default;
$table-striped-column-cell-bg-color: var(--gl-color-light-grey) !default;

.gl-table {
  display: block;
  overflow: auto;
}

.gl-table__inner {
  height: max-content;
  min-width: 100%;
  width: max-content;

  @include gl-ie-only() {
    display: inline-block;
  }
}

.gl-table__row {
  align-items: stretch;
  display: flex;
  min-width: 100%;
  width: max-content;
}

.gl-table__cell {
  align-items: center;
  background: var(--gl-color-secondary);
  box-sizing: border-box;
  display: flex;
  flex: 1 1 $table-cell-width;
  font-family: $font-body-family;
  font-size: map-fetch($fonts, 'body' 'default' 'sizes' 's');
  font-style: $font-body-style;
  font-weight: $font-body-weight;
  justify-content: stretch;
  line-height: $table-cell-line-height;
  margin: 0;
  min-height: $table-cell-height;
  padding: $table-cell-padding;
  position: relative;
  text-align: center;
  width: $table-cell-width;
}

.gl-table__cell-inner {
  width: 100%;
}

.gl-table--sticky-lead-column {
  .gl-table__cell:first-child {
    border-right: $table-cell-border-width solid var(--gl-color-primary);
    font-weight: $font-body-weight--bold;
    left: 0;
    position: sticky;
    z-index: 1;
  }
}

.gl-table__row--head {
  .gl-table__cell {
    background: var(--gl-color-primary);
    color: var(--gl-color-secondary);
    font-weight: $font-body-weight--bold;
    margin: 0;
  }
}

.gl-table--sticky-head-row {
  max-height: 70vh;

  .gl-table__row--head {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.gl-table--striped-columns {
  .gl-table__row--body .gl-table__cell:nth-child(2n) {
    background: $table-striped-column-cell-bg-color;
  }
}

.gl-table--striped-rows {
  .gl-table__row--body:nth-child(2n + 1) .gl-table__cell {
    background: $table-striped-column-cell-bg-color;
  }
}

.gl-table__row--striped .gl-table__cell {
  background: $table-striped-column-cell-bg-color;
}

// Column
.gl-table__cell--highlight {
  border-left: $table-cell-border-width solid var(--gl-color-primary);
  border-right: $table-cell-border-width solid var(--gl-color-primary);
  padding-left: $table-cell-padding - $table-cell-border-width;
  padding-right: $table-cell-padding - $table-cell-border-width;
}

// Body row
.gl-table__row--body.gl-table__row--highlighted .gl-table__cell {
  border-bottom: $table-cell-border-width solid var(--gl-color-primary);
  border-top: $table-cell-border-width solid var(--gl-color-primary);
  padding-bottom: $table-cell-padding - $table-cell-border-width;
  padding-top: $table-cell-padding - $table-cell-border-width;
}

// Head row
.gl-table__row--head .gl-table__cell--highlight {
  border-left: $table-cell-border-width solid var(--gl-color-secondary);
  border-right: $table-cell-border-width solid var(--gl-color-secondary);
}

// Highlight on hover
.gl-table--highlight-cell-on-hover {
  .gl-table__row--body:hover .gl-table__cell {
    border-bottom: $table-cell-border-width solid var(--gl-color-primary);
    border-top: $table-cell-border-width solid var(--gl-color-primary);
    padding-bottom: $table-cell-padding - $table-cell-border-width;
    padding-top: $table-cell-padding - $table-cell-border-width;
  }
}
