/**
 * Do not edit directly
 * Generated on Wed, 09 Jun 2021 12:48:16 GMT
 */

:root {
  --gl-color-bright-blue: #0071ae;
  --gl-color-hi-res-aqua: #00a2a2;
  --gl-color-shock-yellow: #ede734;
  --gl-color-shock-lime: #2ada71;
  --gl-color-shock-pink: #da1a7b;
  --gl-color-legend-ink: #3a3e4f;
  --gl-color-raw-grey: #6d8194;
  --gl-color-ash-green: #a7c1b9;
  --gl-color-trace-khaki: #a39582;
  --gl-color-trace-maroon: #b56971;
  --gl-color-off-white: #f5f5e5;
  --gl-color-medium-grey: #d3d7da;
  --gl-color-gold: #8a7442;
  --gl-color-green: #00aa55;
  --gl-color-red: #e32b2b;
  --gl-color-light-grey: #ebedee;
  --gl-color-dark-grey: #767677;
  --gl-color-white: #ffffff;
  --gl-color-black: #000000;
  --gl-color-primary: var(--gl-color-black);
  --gl-color-secondary: var(--gl-color-white);
  --gl-color-accent: var(--gl-color-primary);
  --gl-color-membership-primary: var(--gl-color-gold);
  --gl-color-membership-secondary: var(--gl-color-gold);
  --gl-color-validation-error: var(--gl-color-red);
  --gl-color-validation-success: var(--gl-color-green);
}
