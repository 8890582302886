$carousel-border-bottom-highlight: 2px solid transparent !default;
$prev-next-button-bg-color: transparent !default;
$prev-next-button-color: var(--gl-color-primary) !default;
$prev-next-button-border: none !default;
$prev-next-button-hover-bg-color: var(--gl-color-primary) !default;
$prev-next-button-hover-color: var(--gl-color-secondary) !default;
$prev-next-button-light-color: var(--gl-color-primary) !default;
$prev-next-button-square-type-border: 1px solid var(--gl-color-primary) !default;
$prev-next-button-square-type-bg-color: var(--gl-color-secondary) !default;
$prev-next-button-square-type-padding: 12px !default;
$prev-next-button-square-type-hover-bg-color: $prev-next-button-hover-bg-color !default;
$prev-next-button-square-type-hover-color: $prev-next-button-hover-color !default;
$prev-next-button-square-type-hover-border: $prev-next-button-square-type-border !default;

@mixin prev-next-button {
  align-items: center;
  background-color: $prev-next-button-bg-color;
  border: $prev-next-button-border;
  color: $prev-next-button-color;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: color map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default'),
    background-color map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default'),
    opacity map-fetch($motion, 'duration' 'default')
      map-fetch($motion, 'ease' 'default');
  z-index: 50;

  &:hover {
    background-color: $prev-next-button-hover-bg-color;
    color: $prev-next-button-hover-color;
  }

  &:focus {
    outline: none;
  }

  &--hidden {
    opacity: 0;
    transition: opacity map-fetch($motion, 'duration' 'default')
        map-fetch($motion, 'ease' 'default'),
      visibility map-fetch($motion, 'duration' 'default')
        map-fetch($motion, 'ease' 'default')
        map-fetch($motion, 'duration' 'default');
    visibility: hidden;
  }

  &--next {
    right: 0;
  }

  &--next-outside {
    margin-right: -17px;

    &.gl-carousel__prev-next-button--square-type {
      margin-right: -25px;
    }

    &.gl-carousel__prev-next-button--vertical-type {
      margin-right: -20px;
    }
  }

  &--prev {
    .gl-carousel__prev-next-icon {
      transform: rotate(180deg);
    }
  }

  &--prev-outside {
    margin-left: -17px;

    &.gl-carousel__prev-next-button--square-type {
      margin-left: -25px;
    }

    &.gl-carousel__prev-next-button--vertical-type {
      margin-left: -20px;
    }
  }
}

@mixin prev-next-button--light {
  color: $prev-next-button-light-color;

  @media (pointer: fine) {
    &:hover {
      background-color: var(--gl-color-secondary);
      color: var(--gl-color-primary);
    }
  }

  &:active {
    background-color: var(--gl-color-secondary);
    color: var(--gl-color-primary);
  }
}

@mixin prev-next-button--square-type {
  background-color: $prev-next-button-square-type-bg-color;
  border: $prev-next-button-square-type-border;
  padding: $prev-next-button-square-type-padding;

  &:hover {
    background-color: $prev-next-button-square-type-hover-bg-color;
    border: $prev-next-button-square-type-hover-border;
    color: $prev-next-button-square-type-hover-color;
  }
}

@mixin prev-next-button--vertical-type {
  height: 100%;
  width: 20px;
}

.gl-carousel {
  $carousel-gutter: 1px;
  $carousel-pagination-height: 30px;
  $carousel-pagination-marginTop: 20px;

  &__content {
    position: relative;
  }

  &__slider {
    display: flex;
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    scrollbar-width: none; /* Firefox */
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
    }

    &--snap-old-spec {
      scroll-snap-points-x: repeat(100%);
    }

    &--disable-swipe {
      pointer-events: none;

      > * {
        pointer-events: all;
      }
    }
  }

  &__title {
    margin-bottom: $space-small;
  }

  &--space-on-sides &__title {
    margin-left: 15px;
    margin-right: 15px;
  }

  &__page {
    display: flex;
    min-width: 100%;
    scroll-snap-align: center;
  }

  &__item-wrapper {
    flex: 0 0 auto;
    margin: 0;
    min-height: 1px;
    scroll-snap-align: none;

    &--add-snap {
      scroll-snap-align: start;
    }

    &--highlight {
      border-bottom: $carousel-border-bottom-highlight;
    }

    &:last-child:after {
      border-top: 1px solid transparent;
      content: '';
      display: block;
      left: 100%;
      margin-top: -1px;
      position: relative;
      top: -100%;
      width: 0;
    }

    &--highlight:hover,
    &--active {
      border-bottom: 2px solid var(--gl-color-primary);
    }
  }

  &__prev-next-button {
    @include prev-next-button();

    &--square-type {
      @include prev-next-button--square-type();
    }

    &--vertical-type {
      @include prev-next-button--vertical-type();
    }

    &--light {
      @include prev-next-button--light();
    }
  }

  &--show-pagination &__prev-next-button,
  &--show-pagination &__prev-next-button--light {
    top: calc(50% - (#{$carousel-pagination-height} / 2));
  }

  .gl-pagination {
    margin-top: $carousel-pagination-marginTop;
  }
}
