$toggle-item-background-color: var(--gl-color-secondary) !default;
$toggle-item-border-color: var(--gl-color-dark-grey) !default;
$toggle-item-border-style: solid !default;
$toggle-item-border-width: 1px !default;
$toggle-item-text-color: var(--gl-color-dark-grey) !default;
$toggle-item-padding-x: 10px !default;
$toggle-item-padding-y: 16px !default;
$toggle-item-active-background-color: var(--gl-color-secondary) !default;
$toggle-item-active-border-color: var(--gl-color-primary) !default;
$toggle-item-active-text-color: var(--gl-color-primary) !default;

.gl-toggle {
  display: flex;
  padding: 0;

  .gl-menu__item {
    @include gl-body-style('s');

    background-color: $toggle-item-background-color;
    border: $toggle-item-border-width $toggle-item-border-style
      $toggle-item-border-color;
    line-height: 16px;
    list-style: none;
    margin-left: -1px;
    position: relative;
    text-align: center;

    &:first-child {
      margin-left: 0;
    }

    &--active {
      @include gl-body-style('s', 'bold');

      background-color: $toggle-item-active-background-color;
      border-color: $toggle-item-active-border-color;
      line-height: 16px;
      position: relative;
      z-index: 1;

      .gl-menu__element {
        color: $toggle-item-active-text-color;
      }
    }
  }

  .gl-menu__element {
    color: $toggle-item-text-color;
    cursor: pointer;
    padding: 16px 10px;
    transition: color map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
    width: 100%;

    &:focus {
      color: $toggle-item-active-text-color;
    }

    &:hover {
      @media (pointer: fine) {
        color: $toggle-item-active-text-color;
      }
    }
  }
}
