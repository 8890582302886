$sizes: xs, s, m, l, xl;

.gl-label {
  @each $size in $sizes {
    &--#{$size} {
      font-size: map-fetch($fonts, 'labels' 'sizes' #{$size});
      line-height: map-fetch($fonts, 'labels' 'line-heights' #{$size});
    }
  }
}
