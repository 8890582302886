$marquee-height: 30px !default;
$marquee-segment-line-height: inherit !default;

.gl-marquee {
  display: flex;
  line-height: $marquee-height;
  min-height: $marquee-height;
  overflow: hidden;
  position: relative;
  user-select: none;

  &__segment {
    animation: gl-marquee linear infinite;
    display: block;
    flex: 0 0 auto;
    line-height: $marquee-segment-line-height;
    white-space: nowrap;
  }
}

@keyframes gl-marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}
