$pagination-item-height: 12px !default;
$pagination-item-padding-x: 3px !default;
$pagination-item-padding-y: 4px !default;
$pagination-dot-background-color: var(--gl-color-primary) !default;
$pagination-dot-background-color-hover: var(--gl-color-primary) !default;
$pagination-dot-border-radius: 0 !default;
$pagination-dot-height: 1px !default;
$pagination-dot-height-hover: 4px !default;
$pagination-dot-width: 18px !default;
$pagination-enable-light: true !default;
$pagination-dot-background-color-light: var(--gl-color-secondary) !default;
$pagination-dot-background-color-light-hover: var(
  --gl-color-secondary
) !default;
$pagination-enable-shadow: true !default;

.gl-pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0 8px;
  pointer-events: auto;

  &__item {
    align-items: flex-end;
    cursor: pointer;
    display: flex;
    height: $pagination-item-height;
    padding: $pagination-item-padding-y $pagination-item-padding-x;
  }

  &__dot {
    background: $pagination-dot-background-color;
    border-radius: $pagination-dot-border-radius;
    display: block;
    height: $pagination-dot-height;
    transition: map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
    width: $pagination-dot-width;
  }

  @media (pointer: fine),
    screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    &__item:hover &__dot {
      background: $pagination-dot-background-color-hover;
      height: $pagination-dot-height-hover;
    }
  }

  &__item--selected &__dot {
    background: $pagination-dot-background-color-hover;
    height: $pagination-dot-height-hover;
  }

  @if ($pagination-enable-light) {
    &--light &__dot {
      background: $pagination-dot-background-color-light;
    }

    @media (pointer: fine),
      screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      &--light &__item:hover &__dot {
        background: $pagination-dot-background-color-light-hover;
      }
    }

    &--light &__item--selected &__dot {
      background: $pagination-dot-background-color-light-hover;
    }
  }

  @if ($pagination-enable-shadow) {
    &--shadow &__dot {
      box-shadow: 1px 1px 0 var(--gl-color-secondary);
    }

    @if ($pagination-enable-light) {
      &--shadow.gl-pagination--light &__dot {
        box-shadow: 1px 1px 0 var(--gl-color-primary);
      }
    }

    &--autoplay {
      @include gl-respond-to('medium') {
        color: var(--gl-color-secondary);

        .gl-pagination__dot {
          box-shadow: 1px 1px 0 var(--gl-color-primary);
        }
      }
    }
  }
}
