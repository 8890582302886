/**
 * Form Hints
 *
 * 1. CORE Styles
 */

/**
 * 1. CORE Styles
 */

.gl-form-hint {
  color: $form-hint-text-color;
  font-size: $form-hint-font-size;
  font-weight: $form-hint-font-weight;
  line-height: $form-hint-line-height;
  margin-top: $form-hint-margin-top;
  padding-left: $form-hint-padding;
  text-align: left;
}

.gl-form-hint--error {
  color: $form-hint-error-text-color;
  display: none;
  font-weight: $form-hint-error-font-weight;

  .gl-form-item--error &,
  &--show {
    display: block;
  }
}
