$product-card-background-color: var(--gl-color-secondary) !default;
$product-card-text-color: var(--gl-color-primary) !default;
$product-card-border-width: 1px !default;
$product-card-border-color: transparent !default;
$product-card-hover-border-color: var(--gl-color-primary) !default;
$product-card-media-background: var(--gl-color-light-grey) !default;
$product-card-media-border: 0 !default;
$product-card-carousel-padding-x: 20px !default;
$product-card-icon-size: 17px !default;
$product-card-marquee-height: 30px !default;
$product-card-marquee-background-color: var(--gl-color-primary) !default;
$product-card-marquee-text-color: var(--gl-color-secondary) !default;
$product-card-details-min-height: 75px !default;
$product-card-details-top-min-height: 17px !default;
$product-card-details-main-height: 65px !default;
$product-card-details-category-font-family: $font-body-family !default;
$product-card-details-category-font-size: map-fetch(
  $fonts,
  'body' 'default' 'sizes' 'xs'
) !default;
$product-card-details-category-color: var(--gl-color-dark-grey) !default;
$product-card-details-reviews-color: var(--gl-color-dark-grey) !default;
$product-card-details-color-color: var(--gl-color-dark-grey) !default;
$product-card-broken-image-color: var(--gl-color-dark-grey) !default;

.gl-product-card {
  background-color: $product-card-background-color;
  color: $product-card-text-color;
  cursor: pointer;
  position: relative;
  width: 100%;

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
  }

  @include gl-respond-to('large') {
    border: $product-card-border-width solid $product-card-border-color;
  }

  @media (pointer: fine) {
    &:hover {
      z-index: 1;

      @include gl-respond-to('large') {
        border-color: $product-card-hover-border-color;
      }
    }
  }

  &__assets-container {
    position: relative;
  }

  &__media {
    > * {
      display: block;
      width: 100%;
    }

    &--base {
      display: block;
      width: 100%;
    }

    &--hover {
      opacity: 0;
      position: absolute;
      top: 0;
      visibility: hidden;
    }
  }

  &__marquee {
    background-color: $product-card-marquee-background-color;
    color: $product-card-marquee-text-color;
  }

  &__marquee-label {
    line-height: inherit;
    padding: 0 $space;

    &:before,
    &:after {
      content: ' • ';
    }
  }

  &__image,
  &__image-hover {
    display: block;
    width: 100%;
  }

  &__image-hover {
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
  }

  &__assets {
    background: $product-card-media-background;
    border-bottom: $product-card-media-border;
    position: relative;

    &:hover {
      .gl-product-card__image-hover {
        opacity: 1;
        visibility: visible;
      }

      .gl-product-card__media--hover {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__assets-link {
    display: block;
  }

  &__broken-image {
    align-items: center;
    color: $product-card-broken-image-color;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  &__wishlist {
    color: var(--gl-color-primary);
    position: absolute;
    right: 14px;
    top: 14px;

    button {
      background: none;
      border: none;
      padding: 0;
    }

    @include gl-respond-to('large') {
      right: 16px;
      top: 16px;
    }
  }

  &__details {
    clear: both;
    display: block;
    min-height: $product-card-details-min-height;
    overflow: auto;
    padding: 10px;

    &-link {
      display: block;
      min-height: $product-card-details-min-height;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      min-height: $product-card-details-top-min-height;
    }

    &-main {
      height: $product-card-details-main-height;
      line-height: normal;

      &--with-time,
      &--with-custom-cta {
        height: 95px;
      }

      &--small {
        height: $product-card-details-main-height - $product-card-marquee-height;
      }
    }

    &-bottom {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &-icons {
      display: flex;
    }

    &-icon {
      font-size: 0;
      height: $product-card-icon-size;
      margin-left: 8px;
      position: relative;
      width: $product-card-icon-size;

      &--personalization {
        height: $product-card-icon-size;
        width: $product-card-icon-size;
      }
    }

    &--small {
      min-height: $product-card-details-min-height -
        $product-card-marquee-height;
    }
  }

  &__category,
  &__color {
    font-size: map-fetch($fonts, 'labels' 'sizes' 'm');
  }

  &__category {
    color: $product-card-details-category-color;
    flex: 1 1 auto;
    font-family: $product-card-details-category-font-family;
    font-size: $product-card-details-category-font-size;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__price {
    margin-top: 5px;

    &--membership {
      margin-bottom: 5px;
    }
  }

  &__name,
  &__time {
    display: block;
    margin-top: 10px;
    max-height: 30px;
    overflow: hidden;
  }

  &__time {
    + .gl-product-card__name {
      margin-top: 5px;
    }
  }

  &__custom-cta {
    margin-top: 15px;
  }

  &__reviews {
    align-items: flex-start; // should be center but this renders better
    color: $product-card-details-reviews-color;
    display: flex;
    font-size: 10px;
  }

  &__reviews-number {
    font-size: map-fetch($fonts, 'labels' 'sizes' 'm');
    line-height: 1;
    padding-left: 5px;
  }

  &__color {
    color: $product-card-details-color-color;
    line-height: 1;
    text-transform: lowercase;
  }

  &__stars.gl-star-rating {
    &__star {
      height: 11px;
      width: 11px;
    }
  }
}

.gl-product-card-container {
  margin: 0 1px;
  position: relative;

  @include gl-respond-to('large') {
    margin: 0 5px;
  }
}

.gl-product-card-container.show-variation-carousel {
  @include gl-respond-to('large') {
    &:hover {
      .gl-product-card {
        position: absolute;
        width: 100%;
        z-index: 1;
      }

      .gl-product-card__carousel {
        display: block;
      }
    }
  }
}

.gl-product-card__carousel {
  background: $product-card-media-background;
  display: none;
  margin: 3px 0 0;
  overflow-x: hidden;
  padding: 0 $product-card-carousel-padding-x;
  position: relative;
  width: 100%;
}
