$callout-max-width: 729px !default;
$callout-target-width: 20px !default;
$callout-target-height: 20px !default;
$callout-target-distance: 55px !default;
$callout-wrapper-padding: 30px !default;
$callout-wrapper-bg-color: var(--gl-color-light-grey) !default;
$callout-wrapper-border-color: transparent !default;
$callout-wrapper-border-radius: 0 !default;
$callout-wrapper-border-style: none !default;
$callout-wrapper-border-width: 0 !default;
$callout-wrapper-text-color: var(--gl-color-primary) !default;
$callout-wrapper-text-color-urgent: var(--gl-color-primary) !default;
$callout-wrapper-bg-color-urgent: var(--gl-color-secondary) !default;
$callout-wrapper-border-width-urgent: 0 !default;
$callout-wrapper-border-style-urgent: none !default;
$callout-wrapper-border-color-urgent: transparent !default;
$callout-wrapper-text-color-very-urgent: var(--gl-color-secondary) !default;
$callout-wrapper-bg-color-very-urgent: var(--gl-color-primary) !default;
$callout-wrapper-border-width-very-urgent: 0 !default;
$callout-wrapper-border-style-very-urgent: none !default;
$callout-wrapper-border-color-very-urgent: transparent !default;
$callout-wrapper-bg-color-alternative: var(--gl-color-primary) !default;
$callout-wrapper-text-color-alternative: var(--gl-color-primary) !default;
$callout-wrapper-border-width-alternative: 0 !default;
$callout-wrapper-border-style-alternative: none !default;
$callout-wrapper-border-color-alternative: transparent !default;

.gl-callout {
  max-width: $callout-max-width;
  overflow: hidden;
  position: relative;

  @include gl-respond-to('medium') {
    margin-left: 0;
    margin-right: 0;
  }

  &__wrapper {
    background-color: $callout-wrapper-bg-color;
    border-color: $callout-wrapper-border-color;
    border-radius: $callout-wrapper-border-radius;
    border-style: $callout-wrapper-border-style;
    border-width: $callout-wrapper-border-width;
    color: $callout-wrapper-text-color;
    padding: $callout-wrapper-padding;
    position: relative;

    p {
      margin: 10px 0 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    margin-bottom: $space-small;
  }

  &--alternative {
    .gl-callout__wrapper {
      background-color: $callout-wrapper-bg-color-alternative;
      border-color: $callout-wrapper-border-color-alternative;
      border-style: $callout-wrapper-border-style-alternative;
      border-width: $callout-wrapper-border-width-alternative;
      color: $callout-wrapper-text-color-alternative;

      h5,
      p {
        color: $callout-wrapper-text-color-alternative;
      }
    }

    h5,
    p {
      color: $callout-wrapper-text-color-alternative;
    }
  }

  &--urgent {
    .gl-callout__wrapper {
      background-color: $callout-wrapper-bg-color-urgent;
      border-color: $callout-wrapper-border-color-urgent;
      border-style: $callout-wrapper-border-style-urgent;
      border-width: $callout-wrapper-border-width-urgent;
      color: $callout-wrapper-text-color-urgent;

      h5,
      p {
        color: $callout-wrapper-text-color-urgent;
      }
    }
  }

  &--very-urgent {
    .gl-callout__wrapper {
      background-color: $callout-wrapper-bg-color-very-urgent;
      border-color: $callout-wrapper-border-color-very-urgent;
      border-style: $callout-wrapper-border-style-very-urgent;
      border-width: $callout-wrapper-border-width-very-urgent;
      color: $callout-wrapper-text-color-very-urgent;

      h5,
      p {
        color: $callout-wrapper-text-color-very-urgent;
      }
    }
  }
}
