@mixin striped-background($transparent: false) {
  @if $transparent {
    background-color: transparent;
  } @else {
    background-color: var(--gl-color-secondary);
  }

  background-image: repeating-linear-gradient(
    45deg,
    transparent 1px 3px,
    var(--gl-color-primary) 4px 4px,
    transparent 5px 6px
  );
}
