@import 'plugins/rc-slider';

$slider-helper-left-bg-color: var(--gl-color-primary) !default;
$slider-helper-left-double-bg-color: var(--gl-color-dark-grey) !default;
$slider-helper-right-bg-color: var(--gl-color-dark-grey) !default;
$slider-rcslider-rail-bg-color: var(--gl-color-dark-grey) !default;
$slider-disabled-rcslider-track-bg-color: var(--gl-color-dark-grey) !default;
$slider-disabled-rcslider-handle-border-color: var(
  --gl-color-dark-grey
) !default;
$slider-disabled-rcslider-handle-focus-box-shadow-bg-color: var(
  --gl-color-dark-grey
) !default;
$slider-disabled-rcslider-mark-text-color: var(--gl-color-dark-grey) !default;

.gl-slider {
  padding-bottom: 22px;

  &__wrapper {
    margin-top: $space;
    padding: 0 11px;
    position: relative;
  }

  &__helper {
    &--left {
      background-color: $slider-helper-left-bg-color;
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      top: 6px;
      width: 11px;

      .gl-slider--double & {
        background-color: $slider-helper-left-double-bg-color;
      }
    }

    &--right {
      background-color: $slider-helper-right-bg-color;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      right: 0;
      top: 6px;
      width: 11px;
    }
  }

  .rc-slider {
    border-radius: 0;

    &-dot {
      display: none;
    }

    &-rail {
      background-color: $slider-rcslider-rail-bg-color;
      border-radius: 0;
      height: 2px;
      margin-top: 1px;
    }

    &-track {
      background-color: var(--gl-color-primary);
      border-radius: 0;
      height: 2px;
      margin-top: 1px;
    }

    &-handle {
      border: solid 2px var(--gl-color-primary);
      height: 22px;
      margin-top: -9px;
      width: 22px;

      &:active,
      &:focus {
        box-shadow: 0 0 0 5px var(--gl-color-primary);
        height: 12px;
        margin-top: -4px;
        width: 12px;
      }
    }

    &-mark {
      margin-top: 6px;

      &-text {
        color: var(--gl-color-primary);
        opacity: 1;
        width: 15px;

        @include gl-respond-to('medium') {
          white-space: nowrap;
          width: auto;
        }

        &-active {
          font-weight: $font-body-weight--bold;
          opacity: 1;
        }
      }
    }
  }

  &--disabled {
    .gl-slider__helper {
      &--left {
        background-color: var(--gl-color-dark-grey);
      }
    }

    .rc-slider {
      &-disabled {
        background-color: transparent;
      }

      &-track {
        background-color: $slider-disabled-rcslider-track-bg-color;
      }

      &-handle {
        border: solid 2px $slider-disabled-rcslider-handle-border-color;

        &:active,
        &:focus {
          box-shadow: 0 0 0 5px
            $slider-disabled-rcslider-handle-focus-box-shadow-bg-color;
        }
      }

      &-mark {
        &-text {
          color: $slider-disabled-rcslider-mark-text-color;

          &-active {
            font-weight: 400;
          }
        }
      }
    }
  }

  &--min-opaque {
    .rc-slider-mark-text {
      &:first-child {
        opacity: 0.2;
      }
    }
  }

  &--max-opaque {
    .rc-slider-mark-text {
      &:last-child {
        opacity: 0.2;
      }
    }
  }
}
