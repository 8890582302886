$comparison-bar-height: 4px !default;
$comparison-bar-indicator-color: var(--gl-color-primary) !default;
$comparison-bar-triangle-height: 20px !default;
$comparison-bar-triangle-width: 10px !default;
$comparison-bar-triangle-top: -8px !default;
$comparison-bar-triangle-direction: 'down' !default;
$comparison-bar-background-color: var(--gl-color-light-grey) !default;
$comparison-bar-label-color: var(--gl-color-dark-grey) !default;

.gl-comparison-bar {
  position: relative;

  &__bg {
    background-color: $comparison-bar-background-color;
    height: $comparison-bar-height;
    overflow: hidden;
    position: relative;
  }

  &__bar {
    z-index: 1;
  }

  &__indicator {
    background-color: $comparison-bar-indicator-color;
    height: $comparison-bar-height;
    position: absolute;
    top: 0;
    width: 49px;
    z-index: 3;
  }

  &--triangle & {
    &__bg {
      overflow: visible;
    }

    &__indicator {
      background-color: transparent;
      border: $comparison-bar-triangle-width solid transparent;
      border-bottom-width: $comparison-bar-triangle-height;
      border-top-width: $comparison-bar-triangle-height;
      height: auto;
      top: $comparison-bar-triangle-top;
      width: 0;

      @if ($comparison-bar-triangle-direction == 'up') {
        border-bottom-color: $comparison-bar-indicator-color;
      } @else {
        border-top-color: $comparison-bar-indicator-color;
      }
    }

    &__labels {
      margin-top: 10px;
    }
  }

  &__intersections {
    display: flex;
    height: 100%;
    justify-content: space-evenly;
    width: 100%;
  }

  &__intersection {
    background-color: var(--gl-color-secondary);
    height: 4px;
    top: 0;
    width: 4px;
  }

  &__label {
    color: $comparison-bar-label-color;
    display: block;
    font-family: $font-body-family;
    font-size: map-fetch($fonts, 'body' 'default' 'sizes' 'xs');
    font-style: $font-body-style;
    font-weight: $font-body-weight;
    line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 'xs');
    text-align: center;

    &::first-letter {
      text-transform: capitalize;
    }

    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }

    &--active,
    strong {
      color: var(--gl-color-primary);
    }
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

  &__caption {
    font-family: $font-body-family;
    font-size: map-fetch($fonts, 'body' 'default' 'sizes' 's');
    font-style: $font-body-style;
    font-weight: $font-body-weight;
    line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 's');
    margin-top: 10px;
  }

  &__title {
    display: inline-flex;
    font-family: $font-body-family;
    font-size: map-fetch($fonts, 'body' 'default' 'sizes' 'l');
    font-style: $font-body-style;
    font-weight: $font-body-weight;
    line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 'l');
    margin-bottom: 10px;
    text-align: left;

    &::first-letter {
      text-transform: capitalize;
    }

    &--bold {
      font-family: $font-body-family--bold;
      font-style: $font-body-style--bold;
      font-weight: $font-body-weight--bold;
    }
  }
}
