$badge-font-family: $font-label-family !default;
$badge-font-size: map-fetch($fonts, 'labels' 'sizes' 'm') !default;
$badge-letter-spacing: $font-label-letter-spacing !default;
$badge-line-height: 1.1em !default;
$badge-text-transform: uppercase !default;
$badge-text-color: var(--gl-color-primary) !default;
$badge-background-color: var(--gl-color-secondary) !default;
$badge-border-default: none !default;
$badge-border-radius: unset !default;
$badge-left: 7px !default;
$badge-top: 7px !default;
$badge-padding-x: 4px !default;
$badge-padding-y: 4px !default;
$badge-transform: translate(-100%, 0) rotate(270deg) !default;
$badge-transform-origin: right top !default;
$badge-desktop-left: 10px !default;
$badge-desktop-top: 10px !default;
$badge-urgent-background-color: $badge-background-color !default;
$badge-urgent-text-color: $badge-text-color !default;
$badge-urgent-font-weight: $font-label-weight--bold !default;
$badge-urgent-font-family: $font-label-family--bold !default;
$badge-semi-urgent-background-color: $badge-background-color !default;
$badge-semi-urgent-text-color: $badge-text-color !default;
$badge-semi-urgent-font-style: italic !default;
$badge-semi-urgent-font-weight: normal !default;
$badge-inactive-background-color: $badge-background-color !default;
$badge-inactive-text-color: var(--gl-color-dark-grey) !default;
$badge-inactive-font-weight: normal !default;
$badge-inactive-border-default: $badge-border-default !default;

.gl-badge {
  background-color: $badge-background-color;
  border: $badge-border-default;
  border-radius: $badge-border-radius;
  color: $badge-text-color;
  display: inline-block;
  font-family: $badge-font-family;
  font-size: $badge-font-size;
  left: $badge-left;
  letter-spacing: $badge-letter-spacing;
  line-height: $badge-line-height;
  max-width: 90%;
  overflow: hidden;
  padding: $badge-padding-y $badge-padding-x;
  position: absolute;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: $badge-text-transform;
  top: $badge-top;
  transform: $badge-transform;
  transform-origin: $badge-transform-origin;
  white-space: nowrap;

  @include gl-respond-to('large') {
    left: $badge-desktop-left;
    top: $badge-desktop-top;
  }

  &--horizontal {
    left: 0;
    margin: 30px 30px 30px 0;
    top: 0;
    transform: none;
  }

  &--urgent {
    background-color: $badge-urgent-background-color;
    color: $badge-urgent-text-color;
    font-family: $badge-urgent-font-family;
    font-weight: $badge-urgent-font-weight;
  }

  &--semi-urgent {
    background-color: $badge-semi-urgent-background-color;
    color: $badge-semi-urgent-text-color;
    font-style: $badge-semi-urgent-font-style;
    font-weight: $badge-semi-urgent-font-weight;
  }

  &--inactive {
    background-color: $badge-inactive-background-color;
    border: $badge-inactive-border-default;
    color: $badge-inactive-text-color;
    font-weight: $badge-inactive-font-weight;
  }
}
