$progress-indicator-divider-bg: var(--gl-color-dark-grey) !default;
$progress-indicator-divider-step-completed-bg: var(--gl-color-primary) !default;
$progress-indicator-divider-margin: 0 10px !default;

.gl-progress-indicator {
  align-items: center;
  display: flex;

  &__divider {
    background-color: $progress-indicator-divider-bg;
    height: 1px;
    margin: $progress-indicator-divider-margin;
    width: 25px;

    .gl-progress-step--completed + & {
      background-color: $progress-indicator-divider-step-completed-bg;
    }

    &:last-child {
      display: none;
    }
  }
}
