/**
 * Misc
 * 1. Fetched content
 */

/**
 * 1. Fetched content
 */

.gl-fetched-content {
  ul {
    @include gl-list-style('disc');

    li {
      margin-left: $list-item-margin-left;
      padding-left: $list-item-padding-left;
      white-space: normal;
    }
  }

  ol {
    @include gl-list-style('decimal');

    li {
      margin-left: $list-item-margin-left;
      padding-left: $list-item-padding-left;
      white-space: normal;
    }
  }

  h1 {
    @include gl-heading-style('l');
  }

  h2 {
    @include gl-heading-style('m');
  }

  h3,
  h4 {
    @include gl-heading-style('s');
  }
}
