@mixin input-field-bottom($bottom-color) {
  border-bottom: $input-border-width solid $bottom-color;
  box-shadow: 0 1px 0 0 $bottom-color;
}

@mixin input-hoisted-label() {
  background-color: $input-label-background-color-hoisted;
  color: $input-label-text-color-hoisted;
  font-size: $input-label-font-size-hoisted;
  left: ($input-padding-x - $input-label-horizontal-padding-hoisted) +
    $input-border-width;
  line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 's');
  padding: $input-label-vertical-padding-hoisted
    $input-label-horizontal-padding-hoisted;
  padding-bottom: 0;
  top: $input-label-top-hoisted;
  transform: translateX(0);

  &.gl-input__label--centered {
    left: 50%;
    transform: translateX(-50%);
  }
}

.gl-input {
  min-height: $input-height;
  position: relative;

  &__field {
    @include gl-body-style();

    background: $input-background-color;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    box-sizing: border-box;
    color: $input-text-color;
    font-size: map-fetch($fonts, 'body' 'default' 'sizes' 'default');
    line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 'default');
    margin: 0;
    min-height: $input-height;
    padding: $input-padding-top $input-padding-x $input-padding-bottom
      $input-padding-x;
    text-transform: $input-text-transform;
    width: 100%;

    &:focus {
      outline: none;
    }

    &--centered {
      margin: 0;
      text-align: center;
    }

    &--with-icon {
      padding-right: $input-padding-x + 24px + 10px;
    }

    // Hide input type number UI
    &[type='number'] {
      -moz-appearance: textfield; /* stylelint-disable-line  */
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none; /* stylelint-disable-line  */
    }
  }

  &__label {
    color: $input-label-text-color;
    display: flex;
    font-size: $input-label-font-size;
    left: $input-padding-x;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s ease all;

    &--centered {
      left: 50%;
      position: absolute;
      top: calc(50% - 1px);
      transform: translate(-50%, -50%);
      transform-style: preserve-3d;
    }

    // Hoisted when autocomplete for all browsers except IE11 and FF
    input:-webkit-autofill + & {
      @include input-hoisted-label();
    }

    // Hoisted for IE11 and legacy Edge
    // Work perfectly in IE11
    // In legacy Edge, when failed validation, label still overlap input text
    input:focus + &,
    input:not([value='']) + & {
      @include input-hoisted-label();
    }

    // Hoisted for modern browsers
    // Work perfectly in Firefox, Chrome and Safari
    // IE doesn't support :placeholder-shown and will ignore this block
    input:focus + &,
    input:not(:placeholder-shown) + & {
      @include input-hoisted-label();
    }
  }

  .gl-form-item--success & {
    &__icon {
      color: var(--gl-color-validation-success);
    }
  }

  .gl-form-item--error & {
    &__icon {
      color: var(--gl-color-validation-error);
    }
  }

  @if ($input-enable-validation-icon) {
    &__icon {
      position: absolute;
      right: $input-padding-x;
      top: ($input-height - 24px) / 2;
    }
  } @else {
    &__icon {
      display: none !important;
    }
  }
}
