.gl-app {
  @include gl-body-style();

  color: $font-primary-color;
  font-size: map-fetch($fonts, 'body' 'default' 'sizes' 'default');
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-transform: $body-text-transform;

  strong {
    @include gl-body-style(null, 'bold');
  }

  a,
  area,
  button,
  [role='button'],
  input,
  label,
  select,
  summary,
  textarea {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-touch-action: manipulation; /* stylelint-disable-line */
    touch-action: manipulation;
  }
}
