.gl-checkbox {
  text-align: left;

  .gl-form-message,
  .gl-form-hint {
    padding-left: 0;
  }

  &__label {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    line-height: $checkbox-size;
    margin-bottom: 0;
    position: relative;
  }

  &__input {
    cursor: pointer;
    flex-shrink: 0;
    height: $checkbox-size;
    margin: 0 $checkbox-margin-right 0 0;
    opacity: 0;
    pointer-events: all;
    width: $checkbox-size;
  }

  &__icon {
    align-items: center;
    background: $checkbox-background;
    border-color: $checkbox-border-color;
    border-radius: $checkbox-border-radius;
    border-style: solid;
    border-width: $checkbox-border-width;
    box-sizing: border-box;
    color: transparent !important;
    display: flex;
    height: $checkbox-size !important;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: border-color map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
    width: $checkbox-size !important;

    .gl-form-item--has-focus & {
      border-color: $checkbox-border-color-hover;
    }
  }

  &__text {
    display: inline-block;
    font-size: $input-label-font-size;
    padding-left: 1px;
    padding-right: 1px;
    transition: color map-fetch($motion, 'duration' 'fast')
        map-fetch($motion, 'ease' 'default'),
      background-color map-fetch($motion, 'duration' 'fast')
        map-fetch($motion, 'ease' 'default');

    .gl-form-item--has-focus & {
      background-color: var(--gl-color-primary);
      color: var(--gl-color-white);
    }
  }

  &--checked & {
    &__icon {
      background: $checkbox-background-checked;
      border-color: $checkbox-border-color-checked;
      color: $checkbox-check-color !important;
    }
  }

  &:hover & {
    &__icon {
      border-color: $checkbox-border-color-hover;
    }
  }
}
