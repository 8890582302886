/// Respond To
///
/// Defines a specific breakpoint for a given css selector
///
/// @access public
/// @group layout
///
/// @param {string} $breakpoint - The breakpoint for the element
/// @param {string} $direction [$media-direction] - The default $media-direction will be used
/// @param {string} $custom-breakpoint [null] - Only use if extremely necessary
/// @content styles only applied to the media query scope
/// @output content styles inside computed media query block
///
/// @example scss
///   @include gl-respond-to('large', 'max-width') {
///     // styles only applied to large screen
///   }
///
@mixin gl-respond-to(
  $breakpoint,
  $direction: $media-direction,
  $custom-breakpoint: null
) {
  @if $custom-breakpoint {
    // Used for exceptions if really required
    @media (#{$direction}: #{$custom-breakpoint}) {
      @content;
    }
  } @else {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
      // Prints a media query based on the value
      @if $direction == 'max-width' {
        @media (#{$direction}: #{map-get($breakpoints, $breakpoint) - 1px}) {
          @content;
        }
      } @else {
        @media (#{$direction}: #{map-get($breakpoints, $breakpoint)}) {
          @content;
        }
      }
    }

    // If the key doesn't exist in the map
    @else {
      @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}` Available breakpoints are: #{map-keys($breakpoints)}.';
    }
  }
}

/// Safari Only
///
/// Defines a specific style to be used only for Safari
///
/// @access public
/// @group compatibility
///
/// @content styles only applied to safari
/// @output content styles inside computed @media and @supports block
///
/// @example
///   @include gl-safari-only() {
///     // styles only applied to Safari
///   }
@mixin gl-safari-only() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      @content;
    }
  }
}

/// IE11 Only
///
/// Defines a specific style to be used only for IE11
///
/// @access public
/// @group compatibility
///
/// @content styles only applied to IE11
/// @output content styles inside computed @media and @supports block
///
/// @example
///   @include gl-ie-only() {
///     // styles only applied to IE11
///   }
@mixin gl-ie-only() {
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    @content;
  }
}
