/**
 * General Grid Specification Variables
 */

/// Column Gutter Widths.
/// @group grid
$col-gutters: 15px;

/// Columns in Mobile.
/// @group grid
$columns-mob: 12;

/// Columns in Desktop.
/// @group grid
$columns-dt: 24;

/// Container Width Extra large.
/// @group grid
$grid-w-xl: 1280px;

/// Container Width Huge.
/// @group grid
$grid-w-hg: 1600px;

/// Grid Breakpoint Medium.
/// @group grid
$grid-bp-m: map-get($breakpoints, 'medium');

/// Grid Breakpoint Large.
/// @group grid
$grid-bp-l: map-get($breakpoints, 'large');

/// Grid Breakpoint Extra large.
/// @group grid
$grid-bp-xl: map-get($breakpoints, 'extra-large');

/// Grid Breakpoint Huge.
/// @group grid
$grid-bp-hg: map-get($breakpoints, 'huge');

/// Media direction. Mobile First. Should be always `min-width`
/// @group grid
$media-direction: min-width;
