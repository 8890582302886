// Link
// =======

// Table of Contents
// -----------------
// 1. Variables
// 2. General

// 1. Variables
// ------------

// Enable and disable features
$link-enable-light: true !default;

// Links
$link-text-color: var(--gl-color-primary) !default;
$link-text-color-hover: var(--gl-color-secondary) !default;
$link-text-color-active: var(--gl-color-secondary) !default;
$link-bg-color: transparent !default;
$link-bg-color-hover: var(--gl-color-primary) !default;
$link-bg-color-active: var(--gl-color-primary) !default;

// Link Light
$link-light-text-color: var(--gl-color-white) !default;
$link-light-text-color-hover: var(--gl-color-primary) !default;
$link-light-text-color-active: var(--gl-color-primary) !default;
$link-light-bg-color: transparent !default;
$link-light-bg-color-hover: var(--gl-color-white) !default;
$link-light-bg-color-active: var(--gl-color-white) !default;

// 2. General
// ----------

.gl-link,
.gl-fetched-content a {
  background-color: $link-bg-color;
  color: $link-text-color;
  cursor: pointer;
  display: inline;
  padding-left: 1px;
  padding-right: 1px;
  text-decoration: underline;
  transition: color map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default'),
    background-color map-fetch($motion, 'duration' 'fast')
      map-fetch($motion, 'ease' 'default');
  vertical-align: bottom;

  @media (pointer: fine) {
    &:hover {
      background-color: $link-bg-color-hover;
      color: $link-text-color-hover;
    }
  }

  &:active {
    background-color: $link-bg-color-active;
    color: $link-text-color-active;
  }

  .gl-icon {
    margin-right: 7px;
    position: relative;
    top: 1px;
  }

  @if ($link-enable-light) {
    &--light {
      background-color: $link-light-bg-color;
      color: $link-light-text-color;

      @media (pointer: fine) {
        &:hover {
          background-color: $link-light-bg-color-hover;
          color: $link-light-text-color-hover;
        }
      }

      &:active {
        background-color: $link-light-bg-color-active;
        color: $link-light-text-color-active;
      }
    }
  }
}
