$star-rating-color: var(--gl-color-primary) !default;
$star-rating-size: 1em !default;
$star-rating-size-small: 11px !default;

.gl-star-rating {
  color: $star-rating-color;
  display: inline-flex;

  &__item {
    position: relative;
  }

  &__mask {
    overflow: hidden;
    position: absolute;
  }

  &__star {
    height: $star-rating-size;
    width: $star-rating-size;
  }

  &--small {
    .gl-star-rating__star {
      height: $star-rating-size-small;
      width: $star-rating-size-small;
    }
  }
}
