/* css files of the node module 'rc-slider'
 *
 * node_modules/rc-slider/assets/index.css
 * node_modules/rc-tooltip/assets/bootstrap.css
 */

/* node_modules/rc-slider/assets/index.css */

.rc-slider {
  border-radius: 6px;
  box-sizing: border-box;
  height: 14px;
  padding: 5px 0;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-touch-action: none; /* stylelint-disable-line  */
  touch-action: none;
  width: 100%;
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  background-color: #e9e9e9;
  border-radius: 6px;
  height: 4px;
  position: absolute;
  width: 100%;
}

.rc-slider-track {
  background-color: #abe2fb;
  border-radius: 6px;
  height: 4px;
  left: 0;
  position: absolute;
}

.rc-slider-handle {
  background-color: #ffffff;
  border: solid 2px #96dbfa;
  border-radius: 50%;
  cursor: pointer;
  /* stylelint-disable declaration-block-no-duplicate-properties */
  cursor: -webkit-grab; /* stylelint-disable-line  */
  cursor: grab;
  /* stylelint-enable */
  height: 14px;
  margin-top: -5px;
  position: absolute;
  -ms-touch-action: pan-x; /* stylelint-disable-line  */
  touch-action: pan-x;
  width: 14px;
}

.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none;
}

.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}

.rc-slider-handle:hover {
  border-color: #57c5f7;
}

.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing; /* stylelint-disable-line  */
  /* stylelint-disable declaration-block-no-duplicate-properties */
  cursor: grabbing;
  /* stylelint-enable */
}

.rc-slider-mark {
  font-size: 12px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 100%;
}

.rc-slider-mark-text {
  color: #999999;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  text-align: center;
  vertical-align: middle;
}

.rc-slider-mark-text-active {
  color: #666666;
}

.rc-slider-step {
  background: transparent;
  height: 4px;
  position: absolute;
  width: 100%;
}

.rc-slider-dot {
  background-color: #ffffff;
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  bottom: -2px;
  cursor: pointer;
  height: 8px;
  margin-left: -4px;
  position: absolute;
  vertical-align: middle;
  width: 8px;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.rc-slider-dot-reverse {
  margin-left: 0;
  margin-right: -4px;
}

.rc-slider-disabled {
  background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #cccccc;
}

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  background-color: #ffffff;
  border-color: #cccccc;
  box-shadow: none;
  cursor: not-allowed;
}

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}

.rc-slider-vertical {
  height: 100%;
  padding: 0 5px;
  width: 14px;
}

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-track {
  bottom: 0;
  left: 5px;
  width: 4px;
}

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  -ms-touch-action: pan-y; /* stylelint-disable-line  */
  touch-action: pan-y;
}

.rc-slider-vertical .rc-slider-mark {
  height: 100%;
  left: 18px;
  top: 0;
}

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  display: block !important;
  transform: scale(0, 0);
}

.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  display: block !important;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rc-slider-tooltip-zoom-down-in;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rc-slider-tooltip-zoom-down-out;
  animation-play-state: running;
}

@keyframes rc-slider-tooltip-zoom-down-in {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: 50% 100%;
  }

  100% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }
}
@keyframes rc-slider-tooltip-zoom-down-out {
  0% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }

  100% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: 50% 100%;
  }
}

.rc-slider-tooltip {
  box-sizing: border-box;
  left: -9999px;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: -9999px;
  visibility: visible;
}

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px;
}

.rc-slider-tooltip-inner {
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
  color: #ffffff;
  font-size: 12px;
  height: 24px;
  line-height: 1;
  min-width: 24px;
  padding: 6px 2px;
  text-align: center;
  text-decoration: none;
}

.rc-slider-tooltip-arrow {
  border-color: transparent;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #6c6c6c;
  border-width: 4px 4px 0;
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
}

/* node_modules/rc-tooltip/assets/bootstrap.css */

.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}

.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  opacity: 0;
}

.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rc-tooltip-zoom-in;
  animation-play-state: running;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rc-tooltip-zoom-out;
  animation-play-state: running;
}
@keyframes rc-tooltip-zoom-in {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: 50% 50%;
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: 50% 50%;
  }
}
@keyframes rc-tooltip-zoom-out {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: 50% 50%;
  }

  100% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: 50% 50%;
  }
}

.rc-tooltip {
  display: block;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;
  position: absolute;
  visibility: visible;
  z-index: 1070;
}

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px;
}

.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}

.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px;
}

.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}

.rc-tooltip-inner {
  background-color: #373737;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  color: #ffffff;
  min-height: 34px;
  padding: 8px 10px;
  text-align: left;
  text-decoration: none;
}

.rc-tooltip-arrow {
  border-color: transparent;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-top-color: #373737;
  border-width: 5px 5px 0;
  bottom: 4px;
  margin-left: -5px;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-right-color: #373737;
  border-width: 5px 5px 5px 0;
  left: 4px;
  margin-top: -5px;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  margin-top: 0;
  top: 15%;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-left-color: #373737;
  border-width: 5px 0 5px 5px;
  margin-top: -5px;
  right: 4px;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  margin-top: 0;
  top: 15%;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-bottom-color: #373737;
  border-width: 0 5px 5px;
  margin-left: -5px;
  top: 4px;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}
