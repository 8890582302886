/// Heading Style
///
/// Picks the specific size and style for the headings Should only be used on HTML
/// heading elements (h1, h2, h3, h4 or h5)
///
/// @group typography
///
/// @param {string} $size [null] - s, m, l, xl, xxl
/// @param {string} $font-style [null] - secondary, regular, italic, null
///
/// @example scss
///   @include gl-heading-style('xl', 'italic');
///
@mixin gl-heading-style($size: null, $font-style: null) {
  @if $size == 's' {
    font-size: map-fetch($fonts, 'headings' 'default' 'sizes' 'mobile' 's');
    line-height: map-fetch(
      $fonts,
      'headings' 'default' 'line-heights' 'mobile' 's'
    );
    @include gl-respond-to('extra-large') {
      font-size: map-fetch($fonts, 'headings' 'default' 'sizes' 'desktop' 's');
      line-height: map-fetch(
        $fonts,
        'headings' 'default' 'line-heights' 'desktop' 's'
      );
    }
  } @else if $size == 'm' {
    font-size: map-fetch($fonts, 'headings' 'default' 'sizes' 'mobile' 'm');
    line-height: map-fetch(
      $fonts,
      'headings' 'default' 'line-heights' 'mobile' 'm'
    );
    @include gl-respond-to('extra-large') {
      font-size: map-fetch($fonts, 'headings' 'default' 'sizes' 'desktop' 'm');
      line-height: map-fetch(
        $fonts,
        'headings' 'default' 'line-heights' 'desktop' 'm'
      );
    }
  } @else if $size == 'l' {
    font-size: map-fetch($fonts, 'headings' 'default' 'sizes' 'mobile' 'l');
    line-height: map-fetch(
      $fonts,
      'headings' 'default' 'line-heights' 'mobile' 'l'
    );
    @include gl-respond-to('extra-large') {
      font-size: map-fetch($fonts, 'headings' 'default' 'sizes' 'desktop' 'l');
      line-height: map-fetch(
        $fonts,
        'headings' 'default' 'line-heights' 'desktop' 'l'
      );
    }
  } @else if $size == 'xl' {
    font-size: map-fetch($fonts, 'headings' 'default' 'sizes' 'mobile' 'xl');
    line-height: map-fetch(
      $fonts,
      'headings' 'default' 'line-heights' 'mobile' 'xl'
    );
    @include gl-respond-to('extra-large') {
      font-size: map-fetch($fonts, 'headings' 'default' 'sizes' 'desktop' 'xl');
      line-height: map-fetch(
        $fonts,
        'headings' 'default' 'line-heights' 'desktop' 'xl'
      );
    }
  } @else if $size == 'xxl' {
    font-size: map-fetch($fonts, 'headings' 'default' 'sizes' 'mobile' 'xxl');
    line-height: map-fetch(
      $fonts,
      'headings' 'default' 'line-heights' 'mobile' 'xxl'
    );
    @include gl-respond-to('extra-large') {
      font-size: map-fetch(
        $fonts,
        'headings' 'default' 'sizes' 'desktop' 'xxl'
      );
      line-height: map-fetch(
        $fonts,
        'headings' 'default' 'line-heights' 'desktop' 'xxl'
      );
    }
  }

  @if $font-style == 'primary' {
    font-family: $font-heading-primary-family;
    font-style: $font-heading-primary-style;
    font-weight: $font-heading-primary-weight;
  } @else if $font-style == 'secondary' {
    font-family: $font-heading-secondary-family;
    font-style: $font-heading-secondary-style;
    font-weight: $font-heading-secondary-weight;
  } @else if $font-style == 'regular' {
    font-family: $font-heading-family--light;
    font-style: $font-heading-style--light;
    font-weight: $font-heading-weight--light;
  } @else if $font-style == 'italic' {
    font-family: $font-heading-family--italic;
    font-style: $font-heading-style--italic;
    font-weight: $font-heading-weight--italic;
  }
}

/// Body Style
///
/// Picks a specific size and weight for the body typeface
/// Use on HTML Paragraph elements (p) only
///
/// @group typography
///
/// @param {string} $size - s, l
/// @param {string} $font-weight - bold, null
///
/// @example scss
///   @include gl-body-style('s', 'bold');
///
@mixin gl-body-style($size: null, $font-weight: null) {
  @if $size == 'l' {
    font-size: map-fetch($fonts, 'body' 'default' 'sizes' 'l');
    line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 'l');
    margin-bottom: map-fetch($fonts, 'body' 'default' 'margins' 'l');
  } @else if $size == 's' {
    font-size: map-fetch($fonts, 'body' 'default' 'sizes' 's');
    line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 's');
    margin-bottom: map-fetch($fonts, 'body' 'default' 'margins' 's');
  } @else if $size == 'xs' {
    font-size: map-fetch($fonts, 'body' 'default' 'sizes' 'xs');
    line-height: map-fetch($fonts, 'body' 'default' 'line-heights' 'xs');
    margin-bottom: map-fetch($fonts, 'body' 'default' 'margins' 'xs');
  }

  @if $font-weight == 'bold' {
    font-family: $font-body-family--bold;
    font-style: $font-body-style--bold;
    font-weight: $font-body-weight--bold;
  } @else {
    font-family: $font-body-family;
    font-style: $font-body-style;
    font-weight: $font-body-weight;
  }
}

/// Label Style
///
/// Picks the specific size, weight and letter spacing for the labels
/// Use on HTML label elements (span, label) only
///
/// @group typography
///
/// @param {string} $size - s, m, l, xl
/// @param {string} $font-weight [normal] - normal, bold
/// @param {boolean} $condensed - changes letter spacing from default to condensed
///
/// @example scss
///   @include gl-label-style('l', 'bold', true);
///
@mixin gl-label-style($size: null, $font-weight: normal, $condensed: null) {
  text-transform: uppercase;

  @if $size == 'xs' {
    font-size: map-fetch($fonts, 'labels' 'sizes' 'xs');
  } @else if $size == 's' {
    font-size: map-fetch($fonts, 'labels' 'sizes' 's');
  } @else if $size == 'm' {
    font-size: map-fetch($fonts, 'labels' 'sizes' 'm');
  } @else if $size == 'l' {
    font-size: map-fetch($fonts, 'labels' 'sizes' 'l');
  } @else if $size == 'xl' {
    font-size: map-fetch($fonts, 'labels' 'sizes' 'xl');
  }

  @if $font-weight == 'bold' {
    font-family: $font-label-family--bold;
    font-weight: $font-label-weight--bold;
  } @else if $font-weight == 'normal' {
    font-family: $font-label-family;
    font-weight: $font-label-weight;
  }

  @if $condensed == true {
    letter-spacing: map-fetch($fonts, 'labels' 'letter-spacing' 'condensed');
  } @else if $condensed == false {
    letter-spacing: map-fetch($fonts, 'labels' 'letter-spacing' 'default');
  }
}

/// List Style
///
/// Picks the specific style for the lists
/// Use on HTML list elements (ul, ol) only
///
/// @group typography
///
/// @param {string} $style - disc, decimal
///
/// @example scss
///   @include gl-list-style('disc');
///
@mixin gl-list-style($style) {
  @if $style == 'disc' {
    list-style: disc outside;
  } @else if $style == 'decimal' {
    list-style: decimal outside;
  }
}

/// Color and Blur Text Style
///
/// Applies performance-wise blur effect + color to text elements.
/// Instead of filter.blur(), uses text-shadow to set blur and color to typography.
/// Use on text elements only.
/// Does not work with SVG elements.
///
/// @group typography
///
/// @param {string} $color - any Foundation color variable
/// @param {string} $suffix - color prop suffix
///
/// @example scss
///   @include text-color-blurred($color-primary, 'suffix');
///
@mixin text-color-blurred($color, $suffix: null) {
  color: transparent $suffix;
  text-shadow: 0 0 $blur-size-small $color;

  @include gl-respond-to('large') {
    text-shadow: 0 0 $blur-size-large $color;
  }
}
