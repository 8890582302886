/**
 * Form Asterisk
 *
 * 1. CORE Styles
 */

/**
 * 1. CORE Styles
 */

.gl-form-asterisk {
  background-color: inherit;
  color: var(--gl-color-validation-error);
  padding: 0;
  padding-left: 3px;
  visibility: hidden;

  &:after {
    content: '*';
  }

  .gl-form-item--required & {
    visibility: visible;
  }

  &--without-placeholder {
    padding: 0;
  }
}
